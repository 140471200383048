import Utils from "Services/Utils"
import BorderedButton from "components/Button/BorderedButton"
import Title from "components/Header/Title"
import { BUILDER_CONFIG, CHOICE_DISPLAY_VALUE, CHOICE_ID, CHOICE_IMAGE, CHOICE_IS_EXCLUDED, CHOICE_NAME, CONFIG_CUSTOMIZATION, CUSTOMIZATION_THEME_SETTINGS, FRMDATA_SMID, MODEL_DYNAMIC_FIELDS, PAGE_FORM_DATA, EXCLUSION_OPTIONS, PLACEHOLDER_IMAGE, SORT, THEME_PLATFORMS, THEME_SECONDARY } from "constants/constants"
import { useAppContext } from "contexts/AppContext"
import NodeHelper from "helpers/NodeHelper"
import { isArray } from "lodash"
import { FaCheck } from "react-icons/fa"
import { TChoice } from "types"

type MyProps = {
    name: string,
    value?: TChoice[]
    onChange: (value?: string[]|string) => void
    disabled?: boolean
    required?: boolean
    items?: TChoice[]
    isFilter?: boolean
    isMulti?: boolean
    svg?: string
    type?: "big"|"small"|"extrabig",
    description?: string
    image?: string
    sort?: boolean
    info?: string
    exclusionBehaviour?: string
    dataLoading: boolean
}

const SmallGridChoice = (props: MyProps) => {
    const {theme, activePage, platform, builderSpec, getInstances} = useAppContext()
    if(!props.items) return <>No values found</>

    const handleSelect = (choice: TChoice, isSelected: boolean) => {
        /**
         * if value is already selected remove it from the array
         * and pass it to the select function else vice versa
         */
        if(!props.onChange) return

        if(!props.isMulti) {
            props.onChange(isSelected ? [] : choice[CHOICE_ID])
            return
        }

        const newValues = isArray(props.value) ? [...NodeHelper.getChoicesIds(props.value)] : []
        if(isSelected){
            const index = newValues.indexOf(choice[CHOICE_ID])
            if(index !== -1) newValues.splice(index, 1)
        }
        else{
            newValues.push(choice[CHOICE_ID])
        }
        props.onChange(newValues)
    }

    const valuesIds = props.value ? NodeHelper.getChoicesIds(props.value) : []

    // const allDisabled = Utils.checkIfAllChoicesAreDisabled(props.items)

    if(props.items?.length === 0) return (
        <div className="sb3-space-y-2">
            {(!Utils.isConfigurator(theme) || props.type !== "small") && <Title title={props.name} required={props.required} description={props.description} info={props.info}/>}
            <p className="sb3-text-gray-extraDark sb3-text-center sb3-text-sm">No options</p>
        </div>
    )

    const ifAnyVisibleCategory = activePage?.[PAGE_FORM_DATA]?.some((uiModel, modelKey) => {
        const _instances = getInstances(uiModel[FRMDATA_SMID]) ?? []
        return _instances.some((instance, key) => {
            const visibleNodes = NodeHelper.filterVisibleNodesUsingRuntime(instance[MODEL_DYNAMIC_FIELDS], uiModel)
            return visibleNodes.visibleCategoryIds.length > 0
        })
    })

    return (
        <>
        {(!Utils.isConfigurator(theme) || props.type !== "small") && <Title title={props.name} required={props.required} description={props.description} info={props.info}/>}

        <div className={(props.type !== "small" && Utils.isConfigurator(theme)) ? `sb3-grid md:sb3-grid-cols-3 ${(Utils.isLinear(theme) || (ifAnyVisibleCategory && Utils.isConfigurator(theme))) ? "lg:sb3-grid-cols-4" : "lg:sb3-grid-cols-5"} sb3-grid-cols-2 sb3-gap-3` : "sb3-flex sb3-gap-3 sb3-flex-wrap sb3-items-center"}>
            {
                props.items
                ?.filter((item: TChoice) => !item[CHOICE_IS_EXCLUDED] || props.exclusionBehaviour !== EXCLUSION_OPTIONS.HIDE)
                .sort(
                    (props.sort || props.exclusionBehaviour === EXCLUSION_OPTIONS.DISABLE_WITH_SEPERATOR_ORDER) ? 
                    Utils.sortChoices(
                        true, 
                        (props.exclusionBehaviour === EXCLUSION_OPTIONS.DISABLE_WITH_SEPERATOR_ORDER && props.sort) ? SORT.SEPERATE_ASC :
                        (props.sort ?  SORT.ASC : undefined), (item: any) => !!item[CHOICE_DISPLAY_VALUE] ? item[CHOICE_DISPLAY_VALUE]: item[CHOICE_NAME], 
                        (item: any) => item[CHOICE_IS_EXCLUDED]
                    ) as any : undefined
                )
                .map((item, key) => {
                    const _selected = valuesIds?.includes(item?.[CHOICE_ID])

                    return (
                    <BorderedButton 
                            onClick={() => !props.dataLoading && handleSelect(item, _selected)} 
                            key={key}
                            disabled={item[CHOICE_IS_EXCLUDED] || props.disabled} 
                            className={`${props.type === "extrabig" ? "text-center !sb3-p-5" : "sb3-space-x-3 "} ${(props.type === "small" ? "!sb3-py-1 !sb3-text-xs !sb3-min-h-min " : ((Utils.isConfigurator(theme) ? "sb3-py-2 sb3-px-3 " : "!sb3-py-0 !sb3-px-5 ") + '!sb3-text-sm !sb3-text-left'))} 
                                sb3-max-w-full sb3-rounded-none ${!_selected && (Utils.isConfigurator(theme) ? "!sb3-border-gray-dark" : "!sb3-border-gray")} sb3-text-black sb3-min-w-min sb3-flex sb3-items-center sb3-justify-between sb3-relative
                                !sb3-bg-opacity-40
                                ${platform === THEME_PLATFORMS.BIG_COMMERCE ? "!sb3-rounded-lg" : "sb3-rounded-none"}
                            `}
                            active={_selected}
                            isDisabledClickable={true}
                            opacity = {platform !== THEME_PLATFORMS.BIG_COMMERCE && 0.3}
                            style={{backgroundColor:builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_SECONDARY] && platform === THEME_PLATFORMS.BIG_COMMERCE && 
                                builderSpec[BUILDER_CONFIG][CONFIG_CUSTOMIZATION][CUSTOMIZATION_THEME_SETTINGS][THEME_SECONDARY]}}
                        >
                        <span className={`${props.type === "extrabig" ? "sb3-mx-auto sb3-flex-grow sb3-flex-col sb3-justfify-between" : "sb3-space-x-2 sb3-items-center "} sb3-flex sb3-max-w-full sb3-text-center ${(props.type !== "small" && props.type !== "extrabig") ? "sb3-h-12" : ""}`}>
                            {
                                props.isMulti && props.type !== "small" && <span className={`sb3-w-7 sb3-h-7 sb3-p-0.5 sb3-aspect-square sb3-border 
                                ${props.type === "extrabig" && "sb3-absolute sb3-left-2 sb3-top-2"} 
                                ${!item[CHOICE_IS_EXCLUDED] ? "sb3-bg-white" : ''} sb3-border-black`}>
                                    {_selected && <span className="sb3-w-full sb3-h-full sb3-bg-black sb3-aspect-square sb3-block"></span>}
                                </span>

                            }

                            {((!!item[CHOICE_IMAGE] && props.type !== "small") || (props.type === "extrabig")) && <div><img src={item[CHOICE_IMAGE] ? item[CHOICE_IMAGE] : props.type === "extrabig" ? PLACEHOLDER_IMAGE : ""} alt="" className={`${props.type !== "extrabig" ? "sb3-h-10 sb3-w-10 sb3-object-contain" : "sb3-w-full sb3-aspect-square sb3-object-cover"}   sb3-min-w-10`}/></div>}
                            <p title={!!item[CHOICE_DISPLAY_VALUE] ? item[CHOICE_DISPLAY_VALUE]: item[CHOICE_NAME]} className={`sb3-truncate sb3-text-wrap sb3-m-0 ${ props.type === "extrabig" ? "sb3-my-2 sb3-line-clamp-1 " : "sb3-line-clamp-2 "}`}>{((!!item[CHOICE_DISPLAY_VALUE] ? item[CHOICE_DISPLAY_VALUE]: item[CHOICE_NAME]).replaceAll('_', '_\u200B') )}</p>
                        </span>
                        
                        {_selected && props.type !== "small" && props.type !== "extrabig" && <FaCheck className="sb3-aspect-square sb3-w-7"/>}
                    </BorderedButton>
                )})
            }
        </div>
        </>
    )
}

export default SmallGridChoice