import React, { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import { FaCheckCircle, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { IoIosRadioButtonOff } from "react-icons/io";
import { IoRadioButtonOnOutline } from "react-icons/io5";
import BorderlessButton from "components/Button/BorderlessButton";
import { useAppContext } from "contexts/AppContext";
import NodeHelper from "helpers/NodeHelper";
import Utils from "Services/Utils";
import * as consts from "constants/constants";
import { TUI_BuilderPage } from "types";
import { isArray } from "lodash";

export default function LinearPagesGeneric() {
    const {
        activePage,
        setPage,
        getInstances,
        builderSpec,
        specs
    } = useAppContext();

    let currentIndex = useRef<number|null>(null)
    const pagesNavRef = useRef<HTMLDivElement>(null);
    const [scrollIndicator, setScrollIndicator] = useState({ left: false, right: true });
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    useEffect(() => {
        document.querySelector(`#step-submodel-${activePage?.[consts.PAGE_ID]}`)?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }, [activePage]);

    const handleScrollIndicator = () => {
        const el = pagesNavRef.current;
        if (!el) return;

        const left = el.scrollLeft > 0;
        const right = el.scrollWidth - el.scrollLeft - el.clientWidth > 1;
        setScrollIndicator({ left, right });
    };

    useLayoutEffect(() => {
        const el = pagesNavRef.current;
        if (el) {
            el.addEventListener('scroll', handleScrollIndicator);
            handleScrollIndicator();
            return () => el.removeEventListener('scroll', handleScrollIndicator);
        }
    }, []);

    const startDragging = (e: any) => {
        setIsDragging(true);
        if(!pagesNavRef.current) return 
        setStartX(e.pageX - pagesNavRef.current.offsetLeft);
        setScrollLeft(pagesNavRef.current.scrollLeft);
    };

    const stopDragging = () => {
        setIsDragging(false);
    };

    const move = (e: any) => {
        if (!isDragging || !pagesNavRef.current) return;
        e.preventDefault();
        const x = e.pageX - pagesNavRef.current.offsetLeft;
        const walk = (x - startX) * 2;
        pagesNavRef.current.scrollLeft = scrollLeft - walk;
    };

    const isLinear = builderSpec?.[consts.BUILDER_CONFIG][consts.CONFIG_CUSTOMIZATION][consts.CUSTOMIZATION_NAVIGATION] === "linear";

    const newPages = (builderSpec?.[consts.BUILDER_PAGES].filter((page) => {
        return page[consts.PAGE_FORM_DATA].some((builderSubmodel) => {
            const _instances = getInstances(builderSubmodel[consts.FRMDATA_SMID]) ?? [];
            return _instances.length > 0;
        });
    }) ?? []);

    const isNextActive = newPages.slice(-1)[0]?.[consts.PAGE_ID] !== activePage?.[consts.PAGE_ID];
    const isPrevActive = newPages[0]?.[consts.PAGE_ID] !== activePage?.[consts.PAGE_ID];

    function isElementInViewport(el: HTMLDivElement) {
        if(!pagesNavRef.current) return false
        const rect = el.getBoundingClientRect();
        const containerRect = pagesNavRef.current?.getBoundingClientRect();
        
        return (
            rect.left >= containerRect.left &&
            rect.right <= containerRect.right
        );
    }

    const selectNext = () => {
        if(!isLinear){
            if(newPages[(currentIndex.current ?? -1) + 1]) 
                setPage(newPages[(currentIndex.current ?? -1) + 1][consts.PAGE_ID])
            return``
        }

        let flag = -1
        let currentPage: any = null


        const newPage = newPages.find((page) => {
            currentPage = document.querySelector(`#step-submodel-${page[consts.PAGE_ID]}`)
            if(currentPage && isElementInViewport(currentPage)) {
                flag=0
                return false
            }

            if(flag === 0 && !isElementInViewport(currentPage)) return true
        }) ?? newPages[0]

        document.querySelector(`#step-submodel-${newPage[consts.PAGE_ID]}`)?.scrollIntoView({ behavior: 'smooth', block: "nearest",inline: 'center' })

        // let currentPage = document.querySelector(`#step-submodel-${!currentPageIndex.current ? newPages[0][consts.PAGE_ID] : currentPageIndex.current }`)

        // if (currentPage) {
        //     let nextItem = (currentPage.nextElementSibling as HTMLDivElement);
        //     console.log(nextItem)
        //     while (nextItem && nextItem.classList.contains('page-name') && isElementInViewport(nextItem)) {
        //         if(!nextItem.nextElementSibling) break;
        //         nextItem = (nextItem.nextElementSibling as HTMLDivElement);
        //     }
        
        //     if (nextItem) {
        //       nextItem.scrollIntoView({ behavior: 'smooth', block: "nearest",inline: 'center' });
        //       currentPageIndex.current = nextItem[consts.PAGE_ID]
        //     }
        // }
    }

    const selectPrev = () => {
        if(!isLinear){
            if(currentIndex && currentIndex.current !== 0 && newPages[(currentIndex.current ?? -1) - 1]) 
                setPage(newPages[(currentIndex.current ?? -1) - 1][consts.PAGE_ID])
            return 
        }

        let flag = -1
        let currentPage: any = null

        const newPage = newPages.findLast((page) => {
            currentPage = document.querySelector(`#step-submodel-${page[consts.PAGE_ID]}`)
            if(currentPage && isElementInViewport(currentPage)) {
                flag=0
                return false
            }

            if(flag === 0 && !isElementInViewport(currentPage)) return true
        }) ?? newPages[0]

        document.querySelector(`#step-submodel-${newPage[consts.PAGE_ID]}`)?.scrollIntoView({ behavior: 'smooth', block: "nearest",inline: 'center' })
    }

    let lastPage: string;

    // group pages by label
    const pageGroups = newPages.reduce((acc: {[x: string]: TUI_BuilderPage[]}, page) => {
        const key = !!page[consts.PAGE_LABEL] ? page[consts.PAGE_LABEL]: "Other"
        const alLeastOneSubmodelInstance = page[consts.PAGE_FORM_DATA].some((builderSubmodel) => {
            const instances = getInstances(builderSubmodel[consts.FRMDATA_SMID])
            return instances && instances?.length > 0
        })

        if(alLeastOneSubmodelInstance) {
            if(!acc[key]) acc[key] = []
            acc[key].push(page)
            lastPage = page[consts.PAGE_ID]
        }
        
        return acc
    },{})

    if (Object.entries(pageGroups).length === 0) return <></>;

    return (
        <div className="sb3-relative">
            <div 
                ref={pagesNavRef}
                className={`
                    submodelNav sb3-flex sb3-items-center sb3-overflow-x-auto sb3-overflow-y-hidden sb3-py-2 sb3-cursor-grab sb3-select-none sb3-relative sb3-w-min sb3-m-auto
                    sb3-pt-10
                    `}
                style={{
                    scrollbarWidth: 'none',
                    msOverflowStyle: 'none',
                }}
                onMouseDown={startDragging}
                onMouseLeave={stopDragging}
                onMouseUp={stopDragging}
                onMouseMove={move}
            >
                {
                    Object.entries(pageGroups).map(([groupName, pages], index) => {
                        return  <React.Fragment key={index}>
                         <div 
                         className="sb3-space-y-2" 
                         >
                            <div className={`sb3-flex sb3-items-center  ${(isArray(pages) && pages?.length > 0) && 'sb3-last:pr-1'}`}>
                                {
                                pages?.map((page, key) => {
                                    if(activePage?.[consts.PAGE_ID] === page[consts.PAGE_ID]) currentIndex.current = key
                                    // check if any of the submodel is required 
                                    const isRequired = page[consts.PAGE_FORM_DATA].some((builderSubmodel) => {
                                        const modelSubmodel = specs[builderSubmodel[consts.FRMDATA_SMID]]
                                        if(!modelSubmodel) return false
                    
                                        const instances = getInstances(builderSubmodel[consts.FRMDATA_SMID])
                                        if(instances?.length === 0) return false
                    
                                        return instances?.some(instance => NodeHelper.checkIfSubmodelIsRequiredByRuntimeSpec(
                                            instance
                                        ))
                                    })
                    
                                    const isDone = page[consts.PAGE_FORM_DATA].every((builderSubmodel) => {
                                        const modelSubmodel = specs[builderSubmodel[consts.FRMDATA_SMID]]
                                        if(!modelSubmodel) return false
                    
                                        const instances = getInstances(builderSubmodel[consts.FRMDATA_SMID])
                                        if(instances?.length === 0) return false
                    
                                        return instances?.some(instance => NodeHelper.checkIfSubmodelIsDoneByRuntimeSpec(
                                            instance, 
                                            page[consts.PAGE_LAYOUT]
                                        ))
                                    })
                                    
                                    return (
                                        <Fragment key={key}>
                                            <div 
                                                id={`step-submodel-${page?.[consts.PAGE_ID]}`}
                                                className={`sb3-pr-2 page-name`}>
                                                    <BorderlessButton 
                                                        active={activePage?.[consts.PAGE_ID] === page[consts.PAGE_ID]}
                                                        onClick={() => !isLinear 
                                                            && setPage(page[consts.PAGE_ID])}
                                                        className={`sb3-flex sb3-flex-col sb3-items-center sb3-w-max sb3-rounded-none sb3-font-semibold hover:!sb3-text-white sb3-relative !sb3-text-white sb3-z-10  sb3-min-w-min !sb3-p-0 sb3-space-y-2
                                                            ${isLinear && "!sb3-cursor-default"}
                                                            ${
                                                                lastPage !== page[consts.PAGE_ID] && 
                                                                `after:sb3-content-[''] after:sb3-h-0.5 after:sb3-w-full 
                                                                after:sb3-bg-white after:sb3-inline-block after:sb3-absolute 
                                                                after:sb3-left-1/2 after:sb3-top-2 after:-sb3-z-10`
                                                            }
                                                        `}
                                                        >
                                                        { 
                                                            isDone ? <FaCheckCircle 
                                                            className={`sb3-text-white sb3-text-xl sb3-bg-inherit  sb3-bg-blue-500`}
                                                            style={{backgroundColor: builderSpec?.[consts.BUILDER_CONFIG]?.[consts.CONFIG_CUSTOMIZATION]?.[consts.CUSTOMIZATION_THEME_SETTINGS]?.[consts.THEME_PRIMARY_COLOR]}}
                                                            />
                                                            :
                                                            activePage?.[consts.PAGE_ID] === page[consts.PAGE_ID] ? 
                                                                <IoRadioButtonOnOutline className="sb3-text-white sb3-text-xl  sb3-bg-blue-500"
                                                                    style={{backgroundColor: builderSpec?.[consts.BUILDER_CONFIG]?.[consts.CONFIG_CUSTOMIZATION]?.[consts.CUSTOMIZATION_THEME_SETTINGS]?.[consts.THEME_PRIMARY_COLOR]}}
                                                                /> :
                                                                <IoIosRadioButtonOff className="sb3-text-white sb3-text-xl  sb3-bg-blue-500"
                                                                    style={{backgroundColor: builderSpec?.[consts.BUILDER_CONFIG]?.[consts.CONFIG_CUSTOMIZATION]?.[consts.CUSTOMIZATION_THEME_SETTINGS]?.[consts.THEME_PRIMARY_COLOR]}}
                                                                />
                                                        }
                                                        <span className="sb3-text-base sb3-font-semibold hover:!sb3-text-white !sb3-text-white sb3-min-w-[170px] sb3-p-3">
                                                            {
                                                                page[consts.PAGE_NAME]} {(isRequired && !isDone) ? 
                                                                    <span className="sb3-text-red-500">*</span> : 
                                                                    ""
                                                            }
                                                        </span>
                                                        { key === 0 && Object.entries(pageGroups).length > 1 && <span className="sb3-absolute -sb3-top-8 sb3-text-sm sb3-text-gray">{groupName}</span> }
                                                    </BorderlessButton>
                                            </div>
                                        </Fragment>
                                    )
                                })}
                            </div>


                        </div>
                        </React.Fragment>
                    })
                }
                
            </div>
            {
            Utils.checkOverflow(pagesNavRef.current) && (scrollIndicator.left || !isLinear) && 
            (
                <div className="sb3-absolute  sb3-left-0 sb3-z-[1000] sb3-top-0 sb3-h-full sb3-flex sb3-items-center"
                    style={{background: builderSpec?.[consts.BUILDER_CONFIG]?.[consts.CONFIG_CUSTOMIZATION]?.[consts.CUSTOMIZATION_THEME_SETTINGS]?.[consts.THEME_PRIMARY_COLOR]}}
                >
                    <span className="sb3-absolute sb3-left-full sb3-w-20 sb3-h-full sb3-pointer-events-none" 
                     style = {{backgroundImage: `linear-gradient(to right, ${builderSpec?.[consts.BUILDER_CONFIG]?.[consts.CONFIG_CUSTOMIZATION]?.[consts.CUSTOMIZATION_THEME_SETTINGS]?.[consts.THEME_PRIMARY_COLOR]}, transparent)`}}
                    />
                    {(isPrevActive || isLinear) && (
                        <button type="button" className="sb3-ml-3 sb3-text-black sb3-z-10 sb3-bg-light sb3-rounded sb3-p-1.5" onClick={selectPrev}>
                            <FaChevronLeft />
                        </button>
                    )}
                </div>
            )}
            {Utils.checkOverflow(pagesNavRef.current) && (scrollIndicator.right || !isLinear) && (
                <div className="sb3-absolute sb3-right-0 sb3-z-[1000] sb3-top-0 sb3-h-full sb3-flex sb3-items-center"
                    style={{background: builderSpec?.[consts.BUILDER_CONFIG]?.[consts.CONFIG_CUSTOMIZATION]?.[consts.CUSTOMIZATION_THEME_SETTINGS]?.[consts.THEME_PRIMARY_COLOR]}}
                >
                    <span className="sb3-absolute sb3-right-full sb3-w-20 sb3-h-full sb3-pointer-events-none" 
                         style = {{backgroundImage: `linear-gradient(to right, transparent, ${builderSpec?.[consts.BUILDER_CONFIG]?.[consts.CONFIG_CUSTOMIZATION]?.[consts.CUSTOMIZATION_THEME_SETTINGS]?.[consts.THEME_PRIMARY_COLOR]})`}}
                    />
                    {(isNextActive || isLinear) && (
                        <button type="button" className="sb3-text-black sb3-mr-3 sb3-z-10 sb3-bg-light sb3-rounded sb3-p-1.5" onClick={selectNext}>
                            <FaChevronRight />
                        </button>
                    )}
                </div>
            )}
        </div>
    );
}
