import BorderedButton from "components/Button/BorderedButton"
import SB2Sort from "components/Input/SB2Sort"
import SkeletonElement from "components/Table/components/SkeletonElement"
import { BUILDER_CONFIG, CATEGORY_ID, CONFIG_CUSTOMIZATION, CUSTOMIZATION_THEME_SETTINGS, FIELD_ID, FRMDATA_CONFIG, FRMDATA_EXCLUSION, FRMDATA_SMID, MODEL_CATEGORIES, MODEL_DATA, MODEL_DYNAMIC_FIELDS, MODEL_FIELDS, MODEL_SUBMODEL_ID, PAGE_FORM_DATA, PAGE_NAME, RUNTIME_INSTANCE_FIELD_NAME, RUNTIME_INSTANCE_FIELD_VALUE, RUNTIME_INSTANCE_INSTANCE_ID, RUNTIME_INSTANCE_MAX_COUNT, RUNTIME_INSTANCE_MAX_QUANTITY, THEME_SUBTITLES, THEME_TITLE } from "constants/constants"
import { useAppContext } from "contexts/AppContext"
import NodeHelper from "helpers/NodeHelper"
import { isArray } from "lodash"
import { Dispatch, SetStateAction } from "react"
import { FaFilter } from "react-icons/fa"
import Utils from "Services/Utils"
import { TModel, TProductCategoryField } from "types"
import { TCartItem } from "types/Cart"
import { TActiveSubmodel } from "types/contexts/AppContext"

type MyProps = {
    title?:string
    selectedProducts: TCartItem[] | undefined
    setFilterOpen: Dispatch<SetStateAction<boolean>>
    productCount: number,
    submodelName?: string,
    isLoading?: boolean,
    categorySort?: string
    setCategorySort?: Dispatch<SetStateAction<string>>
}

const CategoryHeader = ({
    selectedProducts,
    setFilterOpen,
    productCount,
    submodelName,
    isLoading,
    categorySort,
    setCategorySort,
    title
}: MyProps) => {
    const {
        builderSpec,
        // activeSubmodel,
        specs,
        activePage,
        getInstances
    } = useAppContext()

    const activeSubmodel: TActiveSubmodel = activePage && {
        ...specs[activePage[PAGE_FORM_DATA][0][FRMDATA_SMID]], 
        instance: getInstances(activePage[PAGE_FORM_DATA][0][FRMDATA_SMID])?.[0],
        uiModel: activePage[PAGE_FORM_DATA][0]
    }

    const visibleFields = activeSubmodel && activePage && NodeHelper.filterVisibleNodesUsingRuntime(
        activeSubmodel.instance[MODEL_DYNAMIC_FIELDS],
        activeSubmodel.uiModel
    )

    const isFilterActive = activeSubmodel && isArray(visibleFields?.visibleFieldIds) 
    && (specs[activeSubmodel[MODEL_SUBMODEL_ID]] as TModel)?.[MODEL_DATA][MODEL_FIELDS]
        .filter((field) => visibleFields?.visibleFieldIds.includes(field[FIELD_ID])).length > 0
    && specs[activeSubmodel[MODEL_SUBMODEL_ID]]?.[MODEL_DATA][MODEL_CATEGORIES]
        ?.some((cat: TProductCategoryField) => (activeSubmodel.instance?.[MODEL_DYNAMIC_FIELDS][cat[CATEGORY_ID]][RUNTIME_INSTANCE_MAX_QUANTITY] ?? 1)
        > Utils.getProductQtyByCategoryId(selectedProducts, cat[CATEGORY_ID]) && (activeSubmodel.instance?.[MODEL_DYNAMIC_FIELDS][cat[CATEGORY_ID]][RUNTIME_INSTANCE_MAX_COUNT] ?? 1) > Utils.getUniquePrdtCountByCategoryId(selectedProducts, cat[CATEGORY_ID]))

    const filters = (activeSubmodel?.instance?.[MODEL_DYNAMIC_FIELDS] && Object.entries(activeSubmodel?.instance[MODEL_DYNAMIC_FIELDS])?.filter(([fieldId,field]) => {
        return field.type !== "Category" && visibleFields?.visibleFieldIds?.includes(fieldId) && isArray(field[RUNTIME_INSTANCE_FIELD_VALUE]) && field[RUNTIME_INSTANCE_FIELD_VALUE].length > 0
    })) ?? []

    return (
        <div className="sb3-space-y-4 sb3-max-w-full">

            <div className="sb3-flex sb3-flex-wrap xl:sb3-flex-nowrap lg:sb3-flex-row sb3-flex-col sb3-w-full lg:sb3-justify-between sb3-gap-3">
                <div className="sb3-flex-grow">
                    <h2 style={{color: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_TITLE]}} className="sb3-flex sb3-space-x-2">
                        {title ?? activePage?.[PAGE_NAME]}
                    </h2> 
                    <h4 style={{color: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_SUBTITLES]}} className="sb3-flex sb3-space-x-2 sb3-items-center sb3-w-max sb3-font-bold">
                        <span>{isLoading ? <SkeletonElement type={"title !sb3-w-9 sb3-pr-2"}/> : (productCount > 50 ? "50+" : productCount)}</span> <span>Compatible Selection{productCount > 1 ? 's': ""}</span> 
                        <span className="sb3-text-xs sb3-text-gray-extraDark">({isLoading ? <SkeletonElement type={"text-25 sb3-inline sb3-pr-2"}/> : (productCount > 50 ? "50+" : productCount)} options)</span>
                    </h4>
                    <p>Compatibility based on selections in your build</p>
                </div>

                <div className="sb3-flex lg:sb3-justify-end sb3-justify-between sb3-space-x-2 lg:sb3-space-x-0">
                {
                    isFilterActive &&
                    <div className="sb3-w-1/2 lg:sb3-hidden">
                        <BorderedButton onClick={() => setFilterOpen(prev => !prev)} className="sb3-bg-white !sb3-min-w-full !sb3-text-black !sb3-border-gray-400 hover:sb3-bg-white">
                            <span className="sb3-flex sb3-items-center sb3-justify-start sb3-space-x-2">
                                <FaFilter/><span>Filters {filters.length > 0 && `(${filters.length})`}</span>
                            </span>
                        </BorderedButton>
                    </div>
                }

                {/* <div className="sb3-flex lg:sb3-inline sb3-items-center sb3-w-1/2 lg:sb3-w-full lg:sb3-space-y-1">
                    <label className="sb3-text-sm lg:sb3-block sb3-hidden sb3-ml-0 sb3-font-bold lg:sb3-m-auto">Sort By:</label>
                    <SB2Sort 
                        setCategorySort={setCategorySort} 
                        categorySort={categorySort}
                    />
                </div> */}

            </div>
        </div>

        </div>
    )
}

export default CategoryHeader