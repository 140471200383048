import { BUILDER_CONFIG, CONFIG_CUSTOMIZATION, CUSTOMIZATION_OUT_OF_STOCK_STATUS, CUSTOMIZATION_STOCK_CHECK_TEXT } from "constants/constants"
import { useAppContext } from "contexts/AppContext"

const AvailabilityInput = () => {
    const {
        availabilityFlag,
        setAvailabilityFlag,
        builderSpec
    } = useAppContext()

    return (
        <div>
            <label className="sb3-text-sm sb3-font-bold sb3-text-gray-extraDark sb3-space-x-2">
            <input 
                type="checkbox" 
                onChange={() => setAvailabilityFlag(prev => !prev)} 
                checked={!!availabilityFlag} 
                className="focus:sb3-outline-none focus:sb3-ring-0 focus:!sb3-shadow-none !sb3-border-0 focus:!sb3-border-0 !sb3-m-0 sb3-top-0"
            />
            <span>{!!builderSpec?.[BUILDER_CONFIG][CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_STOCK_CHECK_TEXT] ?
                            builderSpec?.[BUILDER_CONFIG][CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_STOCK_CHECK_TEXT]
                            : "Show In Stock Only"}</span>
            </label>
        </div>
    )
}

export default AvailabilityInput