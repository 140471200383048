import { SORT } from "constants/constants";
import { TApiRuntimeSpec, TChoice, TField, TModel, TResultProduct, TRuntimeInstance, TUI_BuilderSubmodel } from "types";
import * as consts from 'constants/constants';
import { TSelectedValues, TTheme } from "types/contexts/AppContext";
import { isArray } from "lodash";
import { TCart, TCartItem } from "types/Cart";
import AppSettings from 'Settings/AppSettings'

export const isSSR = () => typeof window === 'undefined'

/**
 * General Util functions
 */
export default class Utils {
  static generate_file_name(length: number) {
    let result = "";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  static getUuid(
    a?: any
  ) {
    return a // if the placeholder was passed, return
    ? // a random number from 0 to 15
      (
        a ^ // unless b is 8,
        ((Math.random() * // in which case
          16) >> // a random number from
          (a / 4))
      ) // 8 to 11
        .toString(16) // in hexadecimal
    : // or otherwise a concatenated string:
      (
        ([1e7] as any) + // 10000000 +
        -1e3 + // -1000 +
        -4e3 + // -4000 +
        -8e3 + // -80000000 +
        -1e11
      ) // -100000000000,
      .replace(
        // replacing
        /[018]/g, // zeroes, ones, and eights with
        this.getUuid // random hex digits
      );
  }

  static calculateMixedNumber(input: string) {
    // Regular expression to match a valid fraction pattern
    const fractionPattern = /^(\d+\s)?(\d+)\/(\d+)$/;
    const wholeNumberRegex = /^(\d+)$/;
    const decimalRegex = /^(\d+\.\d+)$/;
  
    // Check if the input is a whole number
    if (wholeNumberRegex.test(input)) {
      return {
        valid: true,
        value: parseInt(input, 10),
      }; 
    }

    // Check if the input is a decimal
    if (decimalRegex.test(input)) {
      return {
        valid: true,
        value: parseFloat(input),
      };
    }

    // Check if the input matches the valid fraction pattern
    const match = input.match(fractionPattern);
  
    if (match) {
      const wholeNumber = match[1] ? parseInt(match[1], 10) : 0;
      const numerator = parseInt(match[2], 10);
      const denominator = parseInt(match[3], 10);
  
      // Check if the denominator is not zero
      if (denominator !== 0 && numerator < denominator) {
        // Calculate the value of the fraction
        const value = wholeNumber + numerator / denominator;
  
        return {
          valid: true,
          value: value,
        };
      } else {
        return {
          valid: false,
          error: "Improper Fraction",
        };
      }
    } else {
      return {
        valid: false,
        error: "Invalid fraction format.",
      };
    }
  }

  static convertToLatex(value: string){
    return value.replace(' ', '\\  ').replace(/(\d+)\/(\d+)/g, '\\frac{$1}{$2}')
  }

  static calculateFraction(mixedNumber: string) {
    const parts = mixedNumber.split(' ');
    
    let wholePart = 0;
    let numerator = 0;
    let denominator = 1;
  
    if (parts.length > 0) {
      wholePart = parseInt(parts[0], 10) || 0;
    }
  
    if (parts.length > 1) {
      const fractionParts = parts[1].split('/');
      if (fractionParts.length > 0) {
        numerator = parseInt(fractionParts[0], 10) || 0;
      }
      if (fractionParts.length > 1) {
        denominator = parseInt(fractionParts[1], 10) || 1;
      }
    }
  
    return wholePart + numerator / denominator;
  }

  static validateNumericField (
    value: string|number,
    options: {
      min?: string|number
      max?: string|number
      increment?: string|number
    }
    ) {
      if(isNaN(Number(value))) return `value should be a number`
      let error = null
      if(options.min && !isNaN(Number(options.min)) && Number(options.min) > Number(value))
          error = (`${value} should be greater than or equal to ${options.min}`)
      else if(options.max && !isNaN(Number(options.max)) && Number(options.max) < Number(value))
          error = (`${value} should be lesser than or equal to ${options.max}`)
      else if(options.increment && !isNaN(Number(options.increment)) && Number(value) % Number(options.increment) !== 0)
          error = (`${value} should be divisible by ${options.increment}`) 

      return error
  }

  static validateStringField (
    value: string,
    options: {
      maxLength?: number,
      required?: boolean
    }
    ) {
      let error = null
      if(options.maxLength && options.maxLength < value.length)
          error = (`${options.maxLength} is the max length`)
      
      return error
  }

  static generateHeadersFromArray(arr: string[]){
    return arr.reduce((acc: {name: string, column: string}[], item) => [...acc, {name: item, column: item}], [])
  }

  static sortChoices (
    sort: boolean|undefined,
    type: string|undefined, 
    getNameCallBack: (item: any) => string, 
    disabledCallback?: (item: any) => boolean, 
  ) {
    if(!sort) return () => true

    return (item1: any, item2: any) => {
      const a = getNameCallBack(item1).replace('"', '').split(' ')[0]
      const b = getNameCallBack(item2).replace('"', '').split(' ')[0]
      if(type === SORT.SEPERATE || !type)
        return (
          (disabledCallback && (disabledCallback(item1) as any) - (disabledCallback(item2) as any)) // disabled
        )
      if(type === SORT.SEPERATE_ASC || !type)
        return (
            (disabledCallback && (disabledCallback(item1) as any) - (disabledCallback(item2) as any)) || // disabled
            ((!isNaN(+a) && !isNaN(+b)) ? +a - +b : 0) || // if both are number
            ((!isNaN(+a) && isNaN(+b)) && 0) || // if 1st is number
            (isNaN(+a) && !isNaN(+b) && 1) || // if 2nd is number
            (isNaN(+a) && isNaN(+b) && a.localeCompare(b) || 0 // if both are string
        ))
      if(type === SORT.SEPERATE_DESC)
        return (
          (disabledCallback && (disabledCallback(item1) as any) - (disabledCallback(item2) as any)) || // disabled
          ((!isNaN(+a) && !isNaN(+b)) ? +b - +a : 0) || // if both are number
          ((!isNaN(+a) && isNaN(+b)) && 1) || // if 1st is number
          (isNaN(+a) && !isNaN(+b) && 0) || // if 2nd is number
          (isNaN(+a) && isNaN(+b) && b.localeCompare(a) || 0 // if both are string
        ))
      if(type === SORT.ASC)
        return (
          ((!isNaN(+a) && !isNaN(+b)) ? +a - +b : 0) || // if both are number
          ((!isNaN(+a) && isNaN(+b)) && 0) || // if 1st is number
          (isNaN(+a) && !isNaN(+b) && 1) || // if 2nd is number
          (isNaN(+a) && isNaN(+b) && a.localeCompare(b) || 0 // if both are string
        ))
      if(type === SORT.DESC)
        return (
          ((!isNaN(+a) && !isNaN(+b)) ? +b - +a : 0) || // if both are number
          ((!isNaN(+a) && isNaN(+b)) && 1) || // if 1st is number
          (isNaN(+a) && !isNaN(+b) && 0) || // if 2nd is number
          (isNaN(+a) && isNaN(+b) && b.localeCompare(a) || 0 // if both are string
        ))
      }
  }

  static checkIfModelIsInstantiable = (model: TModel) => {
    return !(model?.[consts.MODEL_DATA][consts.MODEL_MIN_INSTANTIATION] === 1 
      && model?.[consts.MODEL_DATA][consts.MODEL_MAX_INSTANTIATION] === 1)
  } 

  /**
   * get title for instance 
   * Instantiated title may have different names
   * 
   * @param model - model spec
   * @param instance - instance spec
   * @param isInstantiable - whether instantiable
   * @returns {title: string, isNumbered: boolean}
   */
  static getInstanceTitle = (model: TModel, instance: TRuntimeInstance, isInstantiable: boolean, uiModel: TUI_BuilderSubmodel, platform?: string|null) => {
    let _title:string|number|undefined = undefined;

    const disableInstantiation = model[consts.MODEL_DATA][consts.MODEL_MIN_INSTANTIATION] === model[consts.MODEL_DATA][consts.MODEL_MAX_INSTANTIATION]
    const _name = uiModel?.[consts.FRMDATA_CONFIG]?.[consts.FRMDATA_DISPLAY_NAME]
    
    // if non instantiable then 
    _title = _name

    // if instantiable and - 
    if (isInstantiable) {
      let values = Object.values(instance[consts.MODEL_DYNAMIC_FIELDS]);
      
      // - if 1st field has value use that as name else use default
      if(values.length > 0 && values[0].value) {
        if(isArray(values[0].value))
          _title = values[0].value[0] ?? _name
        else 
          _title = values[0].value ?? _name
      }
    }

    return {
      title: (_title && _title!=='0' && isInstantiable) ? _title : _name,
      isNumbered: !!(!disableInstantiation && ((_title === _name && isInstantiable) || _title === '0'))
    }
  }

  /**
   * get the proper image from item checking
   * AND and OR conditions of the choice
   * 
   * @param {TChoice} item - choice item
   * @param {TSelectedValues} selectedValues -  current selected values to provide appropriate image accordingly with selected values
   * 
   * @return {string|undefined}
   **/
  static getChoiceImage(item: TChoice, selectedValues: TSelectedValues) {
    if(!selectedValues) return undefined

    // if no images return
    const images = (item as TChoice)?.[consts.CHOICE_IMAGE] ? JSON.parse((item as TChoice)?.[consts.CHOICE_IMAGE]?? '') : []
    if(!images?.[0]?.JPEG_IMAGES) return undefined

    // conditions check
    let conditions: string[][] = (item[consts.CHOICE_IMAGE_CONDITIONS] ?? [])
    let orConditions: string[][] = (item[consts.CHOICE_IMAGE_CONDITIONS_OR] ?? [])
    // if no conditions return
    if((!conditions || conditions.length === 0) && (!orConditions || orConditions.length === 0)) return images?.[0]?.JPEG_IMAGES.default_image ?? null

    // create array of selected choice ids without null
    let selectedAttValueIds = Object.values(selectedValues).map((value) => (value[0] as TChoice)[consts.CHOICE_ID] && (value[0] as TChoice)[consts.CHOICE_ID])
                              .filter(value => !!value)
    
    // coditions checks
    let imageArr = [images?.[0]?.JPEG_IMAGES.default_image, ...images?.[0]?.JPEG_IMAGES.images]
      const conditionsCheckResult = conditions?.map((conditionArr, key) => {
        // AND
        let check = conditionArr?.every((value) => {
            return selectedAttValueIds.includes(value) || !value
        })
        
        // if AND then OR
        if(check && orConditions?.[key]?.length > 0) {
            check = orConditions[key]?.some((value) => {
                return selectedAttValueIds.includes(value)
            })
        }

        return check ? conditionArr : []
    })

    // find the largest satisfied array
    const largestConditionsIdx = conditionsCheckResult.reduce((resultIndex, condition, index, arr) => {
        if((condition.length > (arr[resultIndex]?.length ?? 0)))
            return index
        else return resultIndex
    }, -1)

    return largestConditionsIdx !== -1 ? imageArr[largestConditionsIdx] : undefined
  }

  static formatPrice = (num: number) => '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

  static getSelectedProductsFromCategory = (
      cart: TCart, 
      categoryId: string, 
      instanceId: string
    ) => {
      if(!categoryId || !instanceId) return []
      const products = 
        cart.filter((product) => (
          product.category === categoryId
          && product.instanceId === instanceId
        ))

      return products
  }

  static getSelectedProductsFromCategoryIds = (
    cart: TCart, 
    categoryIds: string[], 
    instanceId: string
  ) => {
    if(categoryIds.length <= 0 || !instanceId) return []

    const products = 
      cart.filter((product) => (
        categoryIds.includes(product.category) 
        && product.instanceId === instanceId
      ))

    return products
}


  static get_current_root_url() {
    return window.location.origin + "/";
  }

  static SetWindowTitle(title: string) {
    window.document.title = "SB3 : " + title;
  }

  static getStartEndFromPageAndLimit(page?: number, limit?: number, defaultLimit?: number){
    if(!page && !limit && !defaultLimit) return {start: undefined, end: undefined}

    const start = (((!!page ? page : 1) - 1) * (limit as number ?? defaultLimit))
    const end = start + (limit as number ?? defaultLimit)
    return {
      start,
      end
    }
  }

  static generateQueryParamsFromObject(values: {[x: string]: string|number|boolean|(string|number|boolean)[]|undefined}) {
    if(!values) return undefined

    return Object.keys(values)
    .filter((key) =>  typeof values[key] !== 'object' ? !!values[key] : (values[key] as (string|number)[]).length > 0)
    .map(key => key + '=' + (typeof values[key] !== 'object' ? values[key] : ((values[key] as (string|number)[]).join(', ')))).join('&')
  }

  static getPageAndLimit(startIndex: number, endIndex: number, pageSize: number) {
    const page = Math.floor(startIndex / pageSize) + 1;
    const limit = Math.ceil((endIndex - startIndex + 1) / pageSize);
    return { page, limit };
  }

  static formatRuntimeSpec = (_runtimeSpec: TApiRuntimeSpec) => {
      // _runtimeSpec.instances.forEach(instance => {
      //   Object.keys(instance.dynamic_fields).forEach((fieldKey) => {
      //     instance.dynamic_fields[fieldKey].is_hidden = random(1,2) === 1
      //   })
      // })
      return Object.fromEntries(_runtimeSpec[consts.RUNTIME_INSTANCES].map((instance) => ([instance[consts.RUNTIME_INSTANCE_INSTANCE_ID], instance])))
  }

  static findProductIndex = (_cart: TCart, product: TResultProduct, category: string, instanceId: string) => {
    return _cart.findIndex((cartProduct) => (
        cartProduct[consts.RESULT_PRDT_SKU] === product[consts.RESULT_PRDT_SKU] 
        && cartProduct.category === category 
        && cartProduct.instanceId === instanceId)
    )
  }

  static getProductQtyByCategoryId = (selectedProducts: TCartItem[]|undefined, categoryId: string) => {
    return selectedProducts?.filter((product) => product[consts.RESULT_PRDT_CATEGORY] === categoryId).reduce((acc, product) => acc + (product.quantity ?? 1), 0) ?? 0
  }

  static getUniquePrdtCountByCategoryId = (selectedProducts: TCartItem[]|undefined, categoryId: string) => {
    return selectedProducts?.filter((product) => product[consts.RESULT_PRDT_CATEGORY] === categoryId).reduce((acc, product) => acc + 1, 0) ?? 0
  }

  static checkIfClientWebsite = () => {
    return !!window?.compatioConfig?.magento?.apiEndpointUrl 
    || !!window.compatioConfig?.shopify?.apiEndpointUrl
    || !!window.compatioConfig?.bigCommerce?.apiEndpointUrl
  }

  static isLocal = () => {
    return AppSettings.DEVELOPMENT_ENV === "local"
  }

  static isConfigurator = (theme: TTheme) => {
    return theme === consts.THEMES.CONFIGURATOR || theme === consts.THEMES.TEST
  }

  static isLinear = (theme: TTheme) => {
    return theme === consts.THEMES.LINEAR
  }

  static newShade = (hexColor: string, magnitude: number): string => {
    // Remove # if present
    hexColor = hexColor.replace(`#`, ``);
    
    // Handle 8-digit hex (includes alpha)
    const hasAlpha = hexColor.length === 8;
    const alpha = hasAlpha ? hexColor.slice(6, 8) : '';
    hexColor = hasAlpha ? hexColor.slice(0, 6) : hexColor;
    
    // Validate hex color
    if (hexColor.length !== 6) {
        return `#${hexColor}${alpha}`;
    }
    
    // Convert to RGB
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);
    
    // Adjust each component
    const adjustColor = (color: number): number => {
        const newValue = color + magnitude;
        return Math.min(255, Math.max(0, newValue));
    };
    
    // Get new values
    const newR = adjustColor(r);
    const newG = adjustColor(g);
    const newB = adjustColor(b);
    
    // Convert back to hex with proper padding
    const toHex = (n: number): string => {
        const hex = n.toString(16);
        return hex.length === 1 ? `0${hex}` : hex;
    };
    
    return `#${toHex(newR)}${toHex(newG)}${toHex(newB)}${alpha}`;
  };

  static checkOverflow(el?: HTMLElement|null)
  {
    if(!el) return
    var curOverflow = el.style.overflow;

    if ( !curOverflow || curOverflow === "visible" )
        el.style.overflow = "hidden";

    var isOverflowing = el.clientWidth < el.scrollWidth 
        || el.clientHeight < el.scrollHeight;

    el.style.overflow = curOverflow;

    return isOverflowing;
  }
  
  static generateSummaryData = (
    fields: TField[], 
    instance: TRuntimeInstance, 
    visibleFields: string[]
  ) => {
    return fields.filter((field) => (
        (!(instance as TRuntimeInstance)?.[consts.MODEL_DYNAMIC_FIELDS]?.[field[consts.FIELD_ID]]?.[consts.RUNTIME_INSTANCE_IS_INPUT]) 
            && visibleFields.includes(field[consts.FIELD_ID]) 
            && ((instance as TRuntimeInstance)[consts.MODEL_DYNAMIC_FIELDS][field[consts.FIELD_ID]][consts.RUNTIME_INSTANCE_FIELD_VALUE] as (string|number)[])?.length > 0
      ))
      .map((field) => ({
        title: field[consts.FIELD_NAME], 
        description: ((instance as TRuntimeInstance)[consts.MODEL_DYNAMIC_FIELDS][field[consts.FIELD_ID]][consts.RUNTIME_INSTANCE_FIELD_VALUE] as (string|number)[])?.join(', ')
      }))
  }

  static isMagento = () => {
    return window.compatioConfig?.magento?.apiEndpointUrl
  }

  static isShopify = () => {
    return window.compatioConfig?.shopify?.apiEndpointUrl
  }

  static getClientApi = () => {
    return this.isMagento() ?? this.isShopify()
  }

  static getMerchantKey = () => {
    if(this.isShopify()){
        return window.compatioConfig?.shopify?.merchantKey;
    }
    if(this.isMagento()){
        return window.compatioConfig?.magento?.merchantKey;
    }
    return AppSettings.MERCHANT_KEY
  }

  static getClientProductSku = () => {
    if(!window.compatioConfig?.compatibleProducts?.productSku 
      || window.compatioConfig.compatibleProducts.productSku == 'undefined') return undefined

    return decodeURIComponent(window.compatioConfig.compatibleProducts.productSku)
  }

  static detectBrowser = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf("chrome") > -1) return "chrome";
    if (userAgent.indexOf("safari") > -1) return "safari";
    if (userAgent.indexOf("firefox") > -1) return "firefox";
    if (userAgent.indexOf("edge") > -1) return "edge";
    if (userAgent.indexOf("opera") > -1 || userAgent.indexOf("opr") > -1) return "opera";
    return "unknown";
  };
}
