import { PLACEHOLDER_IMAGE, RESULT_IS_AVAILABLE, RESULT_MANUFACTURER, RESULT_PRDT_CATEGORY, RESULT_PRDT_CATEGORY_NAME, RESULT_PRDT_DESC, RESULT_PRDT_IMAGE, RESULT_PRDT_INSTANCEID, RESULT_PRDT_MODEL_NO, RESULT_PRDT_NAME, RESULT_PRDT_PRICE, RESULT_PRDT_QTY, RESULT_PRDT_SKU, RESULT_PRDT_STOCK_STATUS, RESULT_PRDT_URL_IMAGE, THEME_PLATFORMS } from "constants/constants";
import { TCartItem } from "types/Cart";
import { TResultProduct } from "types";
import Utils from "Services/Utils";
import { isArray } from "lodash";

class ProductHelper {
    static getProductPrimaryTitle(product: TResultProduct|TCartItem, platform?: string|null){
        if(platform === THEME_PLATFORMS.BIG_COMMERCE){
            return this.getProductManufacturerTitle(product)
        }

        if(Utils.isMagento()){
            return this.getProductName(product)
        }

        return this.getProductModelNumber(product)
    }

    static getProductSecondaryTitle(product: TResultProduct|TCartItem, platform?: string|null){
        if(platform === THEME_PLATFORMS.BIG_COMMERCE){
            return this.getProductModelNumber(product)
        }

        if(Utils.isMagento()){
            return (!!this.getProductManufacturerTitle(product)
                ? this.getProductManufacturerTitle(product)
                    + (this.getProductDescription(product) ? ', ' : "")
                : '') + this.getProductDescription(product)
        }

        return this.getProductName(product)
    }

    static getProductTertiaryTitle(product: TResultProduct|TCartItem, platform?: string|null) {
        if(platform === THEME_PLATFORMS.BIG_COMMERCE){
            return this.getProductDescription(product)
        }

        return this.getProductCategoryName(product as TCartItem) 
    }

    static getProductManufacturerTitle(product: TResultProduct|TCartItem){
        return product[RESULT_MANUFACTURER]
    }

    static getProductName(product: TResultProduct|TCartItem) {
        // return product.variantName.includes('Default Title') ? product[RESULT_PRDT_NAME] : product.variantName
        return product[RESULT_PRDT_NAME]
    }

    static getProductQuantity(product: TCartItem) {
        return product[RESULT_PRDT_QTY]
    }

    static getProductInstanceId(product: TCartItem) {
        return product[RESULT_PRDT_INSTANCEID]
    }

    static getProductDescription(product: TResultProduct|TCartItem) {
        return product[RESULT_PRDT_DESC]
    }

    static checkIfProductInStockClient(product: TResultProduct|TCartItem) {
        return product[RESULT_PRDT_STOCK_STATUS]
    }

    // in stock check status from gms
    static checkIfProductInStock(product: TResultProduct|TCartItem) {
        return product[RESULT_IS_AVAILABLE] === true
    }

    static getProductImage(product: TResultProduct|TCartItem, selectedVariant?: string, variants?: TResultProduct[]) {
        if(Utils.isShopify() && isArray(variants) && variants.length > 0){
            return (variants?.find(v => v[RESULT_PRDT_SKU] === selectedVariant) as TResultProduct)?.[RESULT_PRDT_URL_IMAGE] ?? product[RESULT_PRDT_URL_IMAGE]
        }

        if(!!product[RESULT_PRDT_URL_IMAGE]) return product[RESULT_PRDT_URL_IMAGE]
        const pdImages = product[RESULT_PRDT_IMAGE] && product[RESULT_PRDT_IMAGE]!=='None' && JSON.parse(product[RESULT_PRDT_IMAGE])
        return (pdImages?.[0]?.JPEG_IMAGES?.default_image) ? pdImages[0].JPEG_IMAGES.default_image : PLACEHOLDER_IMAGE
    }

    static getProductModelNumber(product: TResultProduct|TCartItem) {
        return product[RESULT_PRDT_MODEL_NO] ?? product[RESULT_PRDT_SKU]
    }

    static getProductSku(product: TResultProduct|TCartItem) {
        return product[RESULT_PRDT_SKU]
    }
    
    static getProductCategoryName(product: TCartItem) {
        return product[RESULT_PRDT_CATEGORY_NAME]
    }

    static getProductCategoryId(product: TCartItem) {
        return product[RESULT_PRDT_CATEGORY]
    }

    static getProductPrice(product: TCartItem | TResultProduct, selectedVariant?: string, variants?: TResultProduct[]){
        if(!Utils.isShopify()) 
            return parseFloat("" + product[RESULT_PRDT_PRICE])

        return parseFloat("" + ((variants?.find(v => v[RESULT_PRDT_SKU] === selectedVariant) as TResultProduct)?.[RESULT_PRDT_PRICE] ?? product[RESULT_PRDT_PRICE]))
    }
}

export default ProductHelper