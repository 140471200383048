import { BUILDER_CONFIG, CONFIG_CUSTOMIZATION, CUSTOMIZATION_THEME_SETTINGS, THEME_FILL_BUTTON_BG, THEME_FILL_BUTTON_CLR, THEME_ONLINE_BUTTON, THEME_PLATFORMS, THEME_PRIMARY_COLOR } from "constants/constants";
import { useAppContext } from "contexts/AppContext";
import React from "react";
import { TButtonProps } from "types/components/Button";

/**
 * Using div here as shopify conflicts with button styles
 */
const BorderedButton = ({
  type = "button",
  border = "2",
  id = "",
  onClick,
  buttonStyle="default",
  disabled = false,
  className=``,
  disabledClassName = "",
  isDisabledClickable,
  children,
  color,
  active,
  opacity,
  style = {}
}: TButtonProps) => {
  const {
    builderSpec, 
    platform
  } = useAppContext()

  const changeOpacity = (color?: string) => {
    // Ensure the color is a valid hex string with 8 characters (including alpha channel)
    if (!opacity || !color ||  color[0] !== '#') return color
    // Calculate the new opacity in hexadecimal format
    const newOpacityHex = Math.round(opacity * 255).toString(16).padStart(2, '0');
  
    // Replace the alpha channel part of the color with the new opacity
    return `${color.substring(0, 7)}${newOpacityHex}`;
  };

  const styles: any = {}
  if(builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]) {
    if(buttonStyle === "primary") {
      const primaryColor = builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_PRIMARY_COLOR] 
      const fillColor = builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_CLR]
      if(primaryColor) {
        styles.borderColor = primaryColor
        styles.color = primaryColor
        if(active) {
          styles.backgroundColor = changeOpacity(primaryColor) 
          styles.borderColor = primaryColor
          if(!opacity) styles.color = fillColor
        }
      }
    }
    else{
      const onlineButtonColor = builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_ONLINE_BUTTON];
      const fillColor = builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_BG]
      const fillLabel = builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_CLR]
      styles.borderColor = onlineButtonColor
      styles.color = onlineButtonColor
      if(active) {
        styles.backgroundColor = changeOpacity(fillColor) 
        styles.borderColor = onlineButtonColor
        if(!opacity) styles.color = fillLabel
      }
    }
  }
  
  return (
      <div
        className={`
          ${(platform === THEME_PLATFORMS.BIG_COMMERCE) ? 'sb3-rounded-lg':'sb3-rounded-none' }
          ${(platform === THEME_PLATFORMS.BIG_COMMERCE && buttonStyle !== "primary") && '!sb3-border-none'}
        sb3-bg-white sb3-border  sb3-box-border
         sb3-cursor-pointer sb3-font-noto-sans !sb3-text-sm
         sb3-min-h-[36px] sb3-m-0 focus:sb3-outline-none sb3-p-2 sb3-min-w-[92px] sb3-py-1 
         sb3-relative sb3-text-center sb3-no-underline sb3-touch-manipulation sb3-select-none sb3-w-auto sb3-font-normal
         hover:sb3-bg-transparent hover:sb3-border-primary hover:sb3-text-black focus:sb3-bg-transparent focus:sb3-border-primary focus:sb3-text-black
         sb3-flex sb3-items-center sb3-justify-center
         sb3-border-primary ${!builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_ONLINE_BUTTON] && `hover:sb3-bg-primary sb3-text-primary hover:sb3-text-white`}
         ${disabled && "!sb3-text-gray-dark hover:sb3-text-gray-dark hover:sb3-bg-white !sb3-bg-gray-300 !sb3-border-gray-dark"}
         ${disabled && !isDisabledClickable && "!sb3-pointer-events-none"}
         ${isDisabledClickable && 'hover:sb3-bg-white'}
         ${active && 'sb3-bg-primary hover:sb3-bg-primary focus:sb3-bg-primary hover:sb3-text-white focus:sb3-text-white'}
          ${className}`}
        id={id}
        // disabled={disabled && !isDisabledClickable}
        onClick={onClick}
        style = {{
          ...style,
          ...styles
        }}
      >
        {children}
      </div>
  );
};

export default BorderedButton;



