import BorderedButton from "components/Button/BorderedButton"
import { THEME_PLATFORMS } from "constants/constants"
import { useAppContext } from "contexts/AppContext"
import { useEffect, useState } from "react"
import { FaMinus, FaPlus } from "react-icons/fa"
import Utils from "Services/Utils"

type MyProps = {
    quantity?: number
    handleChange: (qty: number) => void
    disablePrev: (qty: number) => boolean
    disableNext: (qty: number) => boolean
}

const QuantityInput = ({
    quantity,
    handleChange,
    disablePrev,
    disableNext,
}: MyProps) => {
    const {theme, platform} = useAppContext()

    const [newQty, setNewQty] = useState(quantity ?? 0)

    useEffect(() => {
        setNewQty(quantity ?? 0)
    }, [quantity])

    useEffect(() => {
        if(quantity === newQty || newQty === 0) return

        handleChange(newQty)
    } , [newQty])

    let buttonClassName = `sb3-w-10 ${Utils.isLinear(theme) ? "!sb3-min-h-max !sb3-px-2" : "sb3-aspect-square"} sb3-border-x sb3-bg-gray-200 disabled:bg-white !sb3-p-0 sb3-min-w-min
    hover:disabled:!sb3-bg-white hover:disabled:!sb3-text-black focus:disabled:!sb3-bg-white focus:disabled:!sb3-border-white focus:disabled:!sb3-text-black !sb3-text-xs`

        return (
            <div className={`sb3-flex ${Utils.isLinear(theme) && "sb3-border-y"} sb3-justify-between sb3-items-center sb3-w-max`}>
                <BorderedButton
                onClick={() => {
                    setNewQty(prev => {
                        if(prev === 1) return prev
                        return prev - 1
                    })
                }} disabled={disablePrev(newQty)} active={!disablePrev(newQty)} className={`${buttonClassName}  ${platform === THEME_PLATFORMS.BIG_COMMERCE && "!sb3-rounded-full  !sb3-min-h-7 !sb3-min-w-7"}`}>
                    <span><FaMinus/></span>
                </BorderedButton>
                <span className="sb3-w-10 sb3-font-bold sb3-text-center sb3-text-sm">{newQty}</span>
                <BorderedButton
                onClick={() => {
                    setNewQty(prev => prev + 1)
                }} disabled={disableNext(newQty)} active={!disableNext(newQty)} className={`${buttonClassName}  ${platform === THEME_PLATFORMS.BIG_COMMERCE && "!sb3-rounded-full  !sb3-min-h-7 !sb3-min-w-7"}`}>
                    <span><FaPlus/></span>
                </BorderedButton>
            </div>
        )
}

export default QuantityInput