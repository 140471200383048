import React from 'react';
import ReactDOM from 'react-dom/client';
import "index.css";
// import "shopify.css";
// import "styles/bigCommerce.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import Anchor from 'components/Anchors/Anchor'
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import "nprogress/nprogress.css";
import { ANCHOR_TYPES } from 'constants/constants';

// Render on client elements 
const sb3Container = document.querySelector('#sb3-container')

if(sb3Container)
  ReactDOM?.createRoot(sb3Container).render(
        <BrowserRouter>
          <Anchor type={ANCHOR_TYPES.BANNER}/>
        </BrowserRouter>
  );

const anchorElementsBtn = document.querySelectorAll('#compatio-sb3-button')
anchorElementsBtn.forEach(element => {
  if(!element) return
  ReactDOM.createRoot(element).render(
      <BrowserRouter>
        <Anchor type={ANCHOR_TYPES.BUTTON}/>
      </BrowserRouter>
  );
});

let anchorElements = []
// Render on client elements 
if(anchorElementsBtn.length === 0) {
  anchorElements = document.querySelectorAll('[compatio-builder]')
  anchorElements.forEach(element => {
    if(!element) return
    ReactDOM.createRoot(element).render(
        <BrowserRouter>
          <Anchor />
        </BrowserRouter>
    );
  });
}


if(anchorElements?.length === 0 && anchorElementsBtn.length === 0) {
  const rootElement = document.getElementById('root')
  const root = rootElement && ReactDOM.createRoot(document.getElementById('root'));
  
  root?.render(
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  );
}  


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
