import Utils from "Services/Utils"
import BorderedButton from "components/Button/BorderedButton"
import Title from "components/Header/Title"
import { CHOICE_DISPLAY_VALUE, CHOICE_ID, CHOICE_IS_EXCLUDED, CHOICE_NAME, EXCLUSION_OPTIONS, PAGE_LAYOUT, PAGE_LAYOUTS, SORT, THEME_PLATFORMS } from "constants/constants"
import { useAppContext } from "contexts/AppContext"
import NodeHelper from "helpers/NodeHelper"
import { isArray } from "lodash"
import { useEffect } from "react"
import { TChoice } from "types"

type MyProps = {
    name: string
    value?: TChoice[]
    onChange: (value?: string[]|string) => void
    disabled?: boolean
    required?: boolean
    items?: TChoice[]
    isFilter?: boolean
    isMulti?: boolean
    description?: string
    info?: string
    sort?: boolean
    exclusionBehaviour?: string
    dataLoading?: boolean
}

const RadioButtons = (props: MyProps) => {
    const {activePage, platform} = useAppContext()
    if(!props.items) return <>No values found</>

    const handleSelect = (choice: TChoice, isSelected: boolean) => {
        /**
         * if value is already selected remove it from the array
         * and pass it to the select function else vice versa
         */
        if(!props.onChange) return

        if(!props.isMulti) {
            props.onChange(isSelected ? [] : choice[CHOICE_ID])
            return
        }

        const newValues = isArray(props.value) ? [...NodeHelper.getChoicesIds(props.value)] : []
        if(isSelected){
            const index = newValues.indexOf(choice[CHOICE_ID])
            if(index !== -1) newValues.splice(index, 1)
        }
        else{
            newValues.push(choice[CHOICE_ID])
        }
        props.onChange(newValues)
    }

    const valuesIds = props.value ? NodeHelper.getChoicesIds(props.value) : []

    // const allDisabled = Utils.checkIfAllChoicesAreDisabled(props.items)

    if(props.items?.length === 0) return (
        <div className="sb3-space-y-2">
            <Title title={props.name} required={props.required} description={props.description} info={props.info}/>
            <p className="sb3-text-gray-extraDark sb3-text-center sb3-text-sm">No options</p>
        </div>
    )
    
    return (
        <div className="sb3-space-y-2">
            <Title title={props.name} required={props.required} description={props.description} info={props.info}/>
            <div className={
                (platform === THEME_PLATFORMS.BIG_COMMERCE && props.items?.length > 10 && activePage?.[PAGE_LAYOUT] === PAGE_LAYOUTS.GUIDED_SELLING) ? "sb3-grid md:sb3-grid-cols-3 lg:sb3-grid-cols-4 sm:sb3-grid-cols-2 sb3-grid-cols-1 sb3-gap-3"
                : (props.items?.length > 10 && activePage?.[PAGE_LAYOUT] === PAGE_LAYOUTS.GUIDED_SELLING) ? "sb3-gap-2 sb3-grid sb3-grid-cols-[repeat(auto-fill,_minmax(250px,_1fr))]" : ""
            }>
            {
                (props.items as any)
                ?.filter((item: TChoice) => !item[CHOICE_IS_EXCLUDED] || props.exclusionBehaviour !== EXCLUSION_OPTIONS.HIDE)
                .sort(
                    (props.sort || props.exclusionBehaviour === EXCLUSION_OPTIONS.DISABLE_WITH_SEPERATOR_ORDER) ? 
                    Utils.sortChoices(
                        true, 
                        (props.exclusionBehaviour === EXCLUSION_OPTIONS.DISABLE_WITH_SEPERATOR_ORDER && props.sort) ? SORT.SEPERATE_ASC :
                        (props.sort ?  SORT.ASC : undefined), (item: any) => !!item[CHOICE_DISPLAY_VALUE] ? item[CHOICE_DISPLAY_VALUE]: item[CHOICE_NAME], 
                        (item: any) => item[CHOICE_IS_EXCLUDED]
                    ) as any : undefined
                )
                .map((item: TChoice, key: number) => {
                    const _selected = valuesIds?.includes(item?.[CHOICE_ID])
                    const _disabled = item[CHOICE_IS_EXCLUDED] || props.disabled

                    return (
                        <div key={key}>
                            <label>
                                <input 
                                    type="radio"
                                    onChange={(e) => !props.dataLoading && handleSelect(item, _selected)} 
                                    disabled={_disabled} 
                                    className="!sb3-py-1 !sb3-text-xs !sb3-min-h-min focus:sb3-outline-none focus:sb3-ring-0 focus:!sb3-shadow-none disabled:sb3-accent-red-200"
                                    checked={_selected}
                                    id={"checkbox-"+item[CHOICE_ID]}
                                    name={'radio-'+props.name}
                                    // active={_selected}
                                    // isDisabledClickable={true}
                                    />
                            <span className="sb3-pl-2 sb3-text-sm">{!!item[CHOICE_DISPLAY_VALUE] ? item[CHOICE_DISPLAY_VALUE]: item[CHOICE_NAME]}</span></label>
                        </div>
                )})
            }
            </div>
        </div>
    )
}

export default RadioButtons