import { fetchResultsApi } from "api"
import Header from "components/Header"
import Table from "components/Table"
import { BUILDER_CONFIG, BUILDER_PAGES, CATEGORY_DISPLAY_TYPES, CATEGORY_ID, CATEGORY_NAME, CMPT_CONF_PRDT_MODEL_NUMBER, CMPT_CONF_PRICE_ENGINE, CONFIG_CUSTOMIZATION, CUSTOMIZATION_OUT_OF_STOCK_STATUS, CUSTOMIZATION_STOCK_CHECK_TEXT, CUSTOMIZATION_THEME_SETTINGS, CUSTOMIZATION_VIEW_PREF, FIELD_BUTTON_LABEL, FIELD_TYPES, FRMDATA_CATEGORY_LABEL, FRMDATA_CONFIG, FRMDATA_DISPLAY_NAME, FRMDATA_ID, FRMDATA_ITEMS, FRMDATA_SMID, FRMDATA_TYPE, MODEL_DATA, MODEL_DYNAMIC_FIELDS, MODEL_SUBMODEL_ID, PAGE_FORM_DATA, PAGE_LAYOUT, PAGE_LAYOUTS, PRDT_DISPLAY_TYPES, RESULT_IS_AVAILABLE, RESULT_MANUFACTURER, RESULT_PARENT_ID, RESULT_PRDT_CATEGORY, RESULT_PRDT_CATEGORY_ID, RESULT_PRDT_DESC, RESULT_PRDT_INSTANCEID, RESULT_PRDT_MODEL_NO, RESULT_PRDT_NAME, RESULT_PRDT_PRICE, RESULT_PRDT_QTY, RESULT_PRDT_SKU, RESULT_PRDT_STOCK_STATUS, RESULT_PRDT_URL, RESULT_PRDT_URL_IMAGE, RUNTIME_ELEMENT_TYPES, RUNTIME_INSTANCE_ELEMENT_TYPE, RUNTIME_INSTANCE_INSTANCE_ID, RUNTIME_INSTANCE_MAX_COUNT, RUNTIME_INSTANCE_MAX_QUANTITY, RUNTIME_INSTANCE_MIN_QUANTITY, SORT, THEME_BACKGROUND, THEME_PLATFORMS, THEME_SECONDARY } from "constants/constants"
import { useCallback, useEffect, useRef, useState } from "react"
import { TModel, TProductCategoryField, TResultProduct, TRuntimeDynamicFieldValue, TRuntimeInstance, TUI_BuilderSubmodel } from "types"
import { TTableHeader } from "types/components/Table"
import Utils from "Services/Utils"
// import { DUMMY_TABLE_DATA } from "constants/dummy_data"
import NProgress from "nprogress"
import { useAppContext } from "contexts/AppContext"
import Product from "components/Product"
import ProductSkeleton from "components/Product/ProductLoading"
import MagentoHelper from "helpers/Clients/Magento"
import { TCartItem } from "types/Cart"
import usePaginate from "hooks/usePaginate"
import PriceEngine from "api/PriceEngineApi"
// import InfiniteScroll from 'react-infinite-scroller';
import InfiniteScroll from 'react-infinite-scroll-component'
import { HiOutlineShoppingBag } from 'react-icons/hi'
import CategoryHeader from "./CategoryHeader"
// import { AnimatePresence, m } from "framer-motion"
import { isArray } from "lodash"
import AuthManager from "Services/AuthManager"
import { FaSpinner } from "react-icons/fa"
import SkeletonElement from "components/Table/components/SkeletonElement"
import Title from "components/Header/Title"
import SB2Sort from "components/Input/SB2Sort"
import ShopifyHelper from "helpers/Clients/Shopify"

type MyProps = {
    categories: TProductCategoryField[]|undefined
    submodel?: TModel
    selectedProducts?: TCartItem[]
    instance?: TRuntimeInstance
    uiModel?: TUI_BuilderSubmodel
    load?: boolean
}
export interface TFilters {
    search?: string,
    sort_by?: string,
    sort_type?: string,
    product_type?: string,
    page?: number,
    limit?: number|string,
    filters?: string[]
    [x: string]: any
}

const MAX_PRODUCTS_PER_PAGE = 50

const Categories = ({
    categories, 
    submodel,
    selectedProducts,
    instance,
    uiModel,
    load
}: MyProps) => {
    const {
        // modelSpec,
        // activePage,
        theme,
        // setShowConfigurations,,
        activePage,
        // sort,
        setFilterOpen,
        categoryDisplayType,
        builderSpec,
        setAvailabilityFlag,
        availabilityFlag,
        runtimeSpec,
        dataLoading,
        platform
    } = useAppContext()

    const [headers, setHeaders] = useState<TTableHeader[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    // if products are not available during initial product fetch then we need to retain the loading state
    const [initialLoading, setInitialLoading] = useState<boolean>(true) 
    const [tableDataLoading, setTableDataLoading] = useState<boolean>(true)
    
    const [compatioProducts, setCompatioProducts] = useState<TResultProduct[]>([])
    // const [sortedCompatioProducts, setSortedCompatioProducts] = useState<TResultProduct[]>([])
    const [totalProducts, setTotalProducts] = useState<number>(0)
    // const [availableProductsCount, setAvailableProductsCount] = useState<number>(0)
    const [categorySort, setCategorySort] = useState<string>(SORT.ASC)

    const categoriesProductsCheck = useRef<boolean[]>([])

    const isConfigurator = Utils.isConfigurator(theme) || Utils.isLinear(theme)

    const prevSort = useRef<string|null>(null)
    const prevCategories = useRef<TProductCategoryField[]|undefined>(undefined)
    const prevInstance = useRef<(TRuntimeDynamicFieldValue|string)[] | undefined>(undefined)
    const prevAvailabilityFlagValue = useRef<typeof availabilityFlag>()

    const categoryIdMap = categories ? Object.fromEntries(categories?.map((category) => ([category[CATEGORY_ID], category]))) : {}
    
    const abortRef = useRef<AbortController>()

    const [trigger, setTrigger] = useState<boolean>(false)
    const [reset, setReset] = useState<boolean>(false)

    const productCountDiff = useRef<number>(0)

    const { 
        rows,
        setPage,
        setLimit,
        page,
        limit
    } = usePaginate<TResultProduct>(
        compatioProducts, 
        1, 
        MAX_PRODUCTS_PER_PAGE,
        "manual"
    )

    // get prices from engine
    const fetchProductPricesBySku = async (skus: string[]) => {
        let priceNumCount = 0;
        const priceEngingPostCall: string[] = [];

        skus.forEach(sku => {
            priceEngingPostCall.push(
            `parts[${priceNumCount}][part_number]=${sku}&`
            );
            priceNumCount++;
        });

        const priceEngineProcess = await PriceEngine.priceEngineProcess(
            priceEngingPostCall.join('')
        );        

        return priceEngineProcess
    }

    // generate table headers for cummins UI
    useEffect(() => {
        if(rows?.length === 0) return

        const headers = Utils.generateHeadersFromArray(Object.keys(rows?.[0]))
        setHeaders(headers)
    }, [rows])

    const setSort = () => {
        const _sort: {
            s_key?: string,
            s_order?: string,
        } = {}

        if(categorySort === SORT.PRICE_ASC || categorySort === SORT.PRICE_DESC) {
            _sort.s_key = RESULT_PRDT_PRICE
            _sort.s_order = categorySort === SORT.PRICE_ASC ? 'asc' : 'desc'
        }
        else if(categorySort === SORT.ALPHABETICAL_ASC || categorySort === SORT.ALPHABETICAL_DESC) {
            _sort.s_key = RESULT_PRDT_NAME
            _sort.s_order = categorySort ===  SORT.ALPHABETICAL_ASC ? 'asc' : 'desc'
        }
        return _sort
    }

    const getCategoryNamesPendingSelection = () => {
        return (categories as TProductCategoryField[])?.filter((cat) => {
            return (instance?.[MODEL_DYNAMIC_FIELDS][cat[CATEGORY_ID]][RUNTIME_INSTANCE_MAX_COUNT] ?? 1) 
                > Utils.getUniquePrdtCountByCategoryId(selectedProducts, cat[CATEGORY_ID]) &&
                (instance?.[MODEL_DYNAMIC_FIELDS][cat[CATEGORY_ID]][RUNTIME_INSTANCE_MAX_QUANTITY] ?? 1) 
                > Utils.getProductQtyByCategoryId(selectedProducts, cat[CATEGORY_ID])
            })
            .map((cat) => cat[MODEL_DATA][CATEGORY_NAME])
    }

    const getShopifyProducts = async (productSkus: string[], products: TResultProduct[]) => {
        // parent ids for shopify
        let _productSkus: string[]|undefined = undefined

        const currentParentIds = compatioProducts.map((product) => product[RESULT_PARENT_ID])

        const parentProductIds = products.map(product => product[RESULT_PARENT_ID])

        const uniqueParentProducts = products
            .filter((product, key) => (key === parentProductIds.indexOf(product[RESULT_PARENT_ID]) 
                && !!product[RESULT_PARENT_ID] && !currentParentIds.includes(product[RESULT_PARENT_ID])))

        _productSkus = uniqueParentProducts.map((product) => product[RESULT_PARENT_ID] as string)

        // if there is a client integration get Availability from 
        // and filter Compatio products
        const clientProducts = await 
            ShopifyHelper.getProductsBySkus(_productSkus)

        clientProducts
        .sort((product1, product2) => parentProductIds.indexOf(product1[RESULT_PRDT_SKU]) - parentProductIds.indexOf(product2[RESULT_PRDT_SKU]))
        .forEach((parentProduct: any) => {
            const categoryId = products.find((product) => {
                return product[RESULT_PARENT_ID] === parentProduct[RESULT_PRDT_SKU]})?.[RESULT_PRDT_CATEGORY_ID] ?? ""
            
            parentProduct[RESULT_PRDT_CATEGORY_ID] = categoryId
        })

        return clientProducts
    }

    const fetchData = async (isInfinite: boolean = false, _isReset?: boolean) => {
        if(!AuthManager.getSessionId() || dataLoading) return

        if(abortRef.current)  abortRef.current?.abort()

        const _sort = setSort()
        const controller = new AbortController()
        abortRef.current = controller
        const signal = controller.signal
        
        try{
            if((isArray(categories) && categories?.length <= 0) || !instance) {
                setInitialLoading(false)
                return new Error("Category missing")
            }
            
            const _categories = getCategoryNamesPendingSelection()

            if((isArray(_categories) && _categories?.length <= 0)) return setInitialLoading(false)
            
            const res = await fetchResultsApi(
                _categories, 
                (instance as TRuntimeInstance)[RUNTIME_INSTANCE_INSTANCE_ID],
                page,
                MAX_PRODUCTS_PER_PAGE,
                0,
                _sort,
                signal,
                false
            )

            if(controller.signal.aborted) return

            if(res.total_prod_count) setTotalProducts(res.total_prod_count ?? 0)
            // if(res.total_prod_count_available) setAvailableProductsCount(res.total_prod_count_available ?? 0)
            const productCount = res.total_prod_count
            
            // if(Utils.isLocal() &&  page === 1) res.products = []

            // if no products found fetch next page products
            if(res?.products.length === 0 
                && isInfinite 
                && productCount > 0 
                && (page * MAX_PRODUCTS_PER_PAGE) < productCount
            ) {
                if(page === 1) {
                    setCompatioProducts([])
                }

                setPage(prev => prev + 1)
                return 
            }

            // const pageCount = Math.ceil(res.total_prod_count/MAX_PRODUCTS_PER_PAGE)
            let filteredProducts = res?.products

            // if(!AppSettings.CLIENT_MAGENTO_API_END_POINT) filteredProducts = res.products
            // else filteredProducts = res?.products?.filter((product) => !availabilityFlag || (product[RESULT_PRDT_STOCK_STATUS] === undefined ? product[RESULT_IS_AVAILABLE] : product[RESULT_PRDT_STOCK_STATUS] === "IN_STOCK"))
            
            let productSkus = res?.products.map((product) => product[RESULT_PRDT_SKU])
            
            // Client Availability check
            if(Utils.isShopify() && res?.products.length > 0) {
                // shopify
                try{
                    filteredProducts = await getShopifyProducts(productSkus, res.products)

                    filteredProducts.map(product => product.catgeory_id = res.products[0].catgeory_id)

                     // if no products found fetch next page products
                    if(filteredProducts.length === 0
                        && isInfinite 
                        && productCount > 0 
                        && (page * MAX_PRODUCTS_PER_PAGE) < productCount
                    ) {
                        if(page === 1) {
                            setCompatioProducts([])
                        }

                        setPage(prev => prev + 1)
                        return 
                    }
                }
                catch(error){
                    // console.debug(error, "Client Inventory check failed, showing all products")
                }
               
                
            }
            else if(Utils.isMagento() && res?.products.length > 0) {
                // magento
                try{
                    // based on client
                    // skus for magento and parent ids for shopify
                    let _productSkus: string[]|undefined = undefined

                    _productSkus = productSkus

                    // if there is a client integration get Availability from 
                    // and filter Compatio products
                    const clientProducts = await 
                        MagentoHelper.getProductsBySkus(_productSkus)

                    // console.log(clientProducts)
                    productSkus = Object.keys(clientProducts)

                    filteredProducts = res.products.filter((product) => {
                        if(!productSkus.includes(product[RESULT_PRDT_SKU])) return false
                        
                        product[RESULT_PRDT_STOCK_STATUS] = clientProducts[product[RESULT_PRDT_SKU]][RESULT_PRDT_STOCK_STATUS]
                        product[RESULT_PRDT_URL_IMAGE] = clientProducts[product[RESULT_PRDT_SKU]][RESULT_PRDT_URL_IMAGE]
                        product[RESULT_PRDT_URL] = clientProducts[product[RESULT_PRDT_SKU]][RESULT_PRDT_URL]
                        product[RESULT_PRDT_NAME] = clientProducts[product[RESULT_PRDT_SKU]][RESULT_PRDT_NAME]
                        product[RESULT_PRDT_MODEL_NO] = clientProducts[product[RESULT_PRDT_SKU]][CMPT_CONF_PRDT_MODEL_NUMBER] ?? product[RESULT_PRDT_MODEL_NO]
                        product[RESULT_PRDT_DESC] = clientProducts[product[RESULT_PRDT_SKU]][RESULT_PRDT_DESC]
                        if(clientProducts[product[RESULT_PRDT_SKU]][RESULT_MANUFACTURER]) product[RESULT_MANUFACTURER] = clientProducts[product[RESULT_PRDT_SKU]][RESULT_MANUFACTURER]
                        if(availabilityFlag && clientProducts[product[RESULT_PRDT_SKU]][RESULT_PRDT_STOCK_STATUS] !== "IN_STOCK") return false
                        return true
                    })
                }
                catch(error){
                    // setCompatioProducts(res.products ?? [])
                    // console.debug("Client Inventory check failed, showing all products")
                }

                // if no products found fetch next page products
                if(filteredProducts.length === 0
                    && isInfinite 
                    && productCount > 0 
                    && (page * MAX_PRODUCTS_PER_PAGE) < productCount
                ) {
                    if(page === 1) {
                        setCompatioProducts([])
                    }

                    setPage(prev => prev + 1)
                    return 
                }
            }
            else {
                // if no clints check gms availability
                filteredProducts = [...filteredProducts?.filter((product) => !availabilityFlag || product[RESULT_IS_AVAILABLE] === true)]
            }


            // whether to replace current products list with new ones
            if(
                _isReset ||
                !isConfigurator
            )  {
                 // if fetched products are less than MAX_PRODUCTS_PER_PAGE then fetch another set of products to negate the difference
                if(filteredProducts.length < MAX_PRODUCTS_PER_PAGE && productCount > 0 
                    && (page * MAX_PRODUCTS_PER_PAGE) < productCount) {
                    productCountDiff.current = productCountDiff.current + filteredProducts.length
                    setPage(prev => prev + 1)
                }
                else {
                    productCountDiff.current = 0
                }
                setInitialLoading(false)
                setCompatioProducts(filteredProducts ?? [])
                return
            }

            try{
                // If third party pricing engines are used 
                // fetch the prices and replace Compatio prices
                if(
                    window.compatioConfig?.compatibleProducts?.[CMPT_CONF_PRICE_ENGINE] !== '0'
                    && window.compatioConfig?.compatibleProducts?.[CMPT_CONF_PRICE_ENGINE] !== 0
                ) {
                    const prices = await fetchProductPricesBySku(productSkus)
        
                    if(prices !== "404"){
                        filteredProducts.forEach((product) => {
                            if(prices[product[RESULT_PRDT_SKU]]) product[RESULT_PRDT_PRICE] = +prices[product[RESULT_PRDT_SKU]].price
                        })
                    }
                }
            }
            catch(err) {
                // console.debug("Price engine call failed, Prices may not be proper")
            }
            
            if(!isConfigurator){
                setCompatioProducts(filteredProducts)
            }
            else {
                // for infinite loading
                setCompatioProducts((prev) => ([...prev, ...filteredProducts]))
            }
            setInitialLoading(false)

            // if fetched products are less than MAX_PRODUCTS_PER_PAGE then fetch another set of products to negate the difference
            if((productCountDiff.current + filteredProducts.length) < MAX_PRODUCTS_PER_PAGE  && productCount > 0 
                && (page * MAX_PRODUCTS_PER_PAGE) < productCount) {
                productCountDiff.current = productCountDiff.current + filteredProducts.length
                setPage(prev => prev + 1)
                return
            }
            else {
                productCountDiff.current = 0
            }
        }
        catch(err: any) {
            // console.error("Error caught:", err.message)
            // toast.error(err.message)
        }
        finally{
            if(controller.signal.aborted) return

            setIsLoading(false)
            setLoading(false)
            setTableDataLoading(false)
            NProgress.done()
            setReset(false)
        }
    }

    useEffect(() => {
        setTableDataLoading(true)
        
        fetchData(true, page === 1)
    }, [page, trigger])

    const handleReset = useCallback(() => {
        NProgress.start()
        setHeaders([])
        setIsLoading(true)
        setLoading(true)
        setPage(1)
        setTrigger(prevTrigger => !prevTrigger)
        setInitialLoading(true)
        setTotalProducts(0)
        productCountDiff.current = 0
    }, [])

    useEffect(() => {
        if(!reset) return
        handleReset()
    }, [reset])

    useEffect(() => {
        handleReset()
    }, [activePage])

    useEffect(() => {
        if(dataLoading) return
        handleReset()
    }, [dataLoading])

    useEffect(() => {
        if(load === false) return 

        const check = categories ? (categories as TProductCategoryField[])?.map(cat => {
            return (instance?.[MODEL_DYNAMIC_FIELDS][cat[CATEGORY_ID]][RUNTIME_INSTANCE_MAX_QUANTITY] ?? 1) <= Utils.getProductQtyByCategoryId(selectedProducts, cat[CATEGORY_ID]) 
            || (instance?.[MODEL_DYNAMIC_FIELDS][cat[CATEGORY_ID]][RUNTIME_INSTANCE_MAX_COUNT] ?? 1) <= Utils.getUniquePrdtCountByCategoryId(selectedProducts, cat[CATEGORY_ID])
        }) : []

        const categoryProductSelectCheckChanged = JSON.stringify(check) !== JSON.stringify(categoriesProductsCheck.current)

        const categoryChanged = JSON.stringify(prevCategories.current) !== JSON.stringify(categories)


        const fields: (TRuntimeDynamicFieldValue|string)[] | undefined = instance?.dynamic_fields && Object.values(instance?.dynamic_fields).filter((field) => field.type !== "Category")
        if(instance?.[RUNTIME_INSTANCE_INSTANCE_ID]) fields?.push(instance[RUNTIME_INSTANCE_INSTANCE_ID])

        const filterValuesChanged = JSON.stringify(prevInstance.current) !== JSON.stringify(fields)

        const sortChanged = prevSort.current !== categorySort
        const availabilityChanged = availabilityFlag !== prevAvailabilityFlagValue.current

        categoriesProductsCheck.current = check
        prevInstance.current = fields
        prevSort.current = categorySort
        prevCategories.current = categories
        prevAvailabilityFlagValue.current = availabilityFlag

        // if any instance value changes,
        // if any category value check changes
        // if categorySort is changed
        // filter value changes
       
        if(!categoryProductSelectCheckChanged && !categoryChanged && !filterValuesChanged && !sortChanged && !availabilityChanged) {
            setLoading(false)
            setIsLoading(false)
            return 
        }

        setReset(true)
    }, [categories, categorySort, availabilityFlag, load])
    
    useEffect(() => {
        if(load === false) return 

        setReset(true)
    }, [runtimeSpec])

    // useEffect(() => {
    //     if(!AppSettings.CLIENT_MAGENTO_API_END_POINT) return setFilteredProducts(compatioProducts)
    //     setFilteredProducts(compatioProducts.filter((product) => !availabilityFlag || product[RESULT_PRDT_STOCK_STATUS] === "IN_STOCK"))
    // }, [compatioProducts, availabilityFlag])

    if(!categories) return <></>

    const isRequired = instance && Object.values(instance[MODEL_DYNAMIC_FIELDS])
    .filter(cat => cat[RUNTIME_INSTANCE_ELEMENT_TYPE] === RUNTIME_ELEMENT_TYPES.CATEGORY)
    .some((cat) => (cat[RUNTIME_INSTANCE_MIN_QUANTITY] ?? 0) > 0)

    if(load === false) return <>
        { 
                activePage?.[PAGE_LAYOUT] === PAGE_LAYOUTS.GUIDED_SELLING && 
                <Title title={`Select ${uiModel?.[FRMDATA_CONFIG]?.[FRMDATA_DISPLAY_NAME]}`} titleClassName="!sb3-text-lg sb3-pt-3"/>
        }

        <div className="sb3-px-2 sb3-py-7 sb3-flex sb3-justify-center sb3-bg-gray sb3-text-gray-extraDark sb3-border">
            <p>Select required option to load {uiModel?.[FRMDATA_CONFIG]?.[FRMDATA_DISPLAY_NAME]}{isRequired && <span className="sb3-text-danger">*</span>}</p>
        </div>
    </>

    const updateFilters = (filters: TFilters) => {
        setPage(filters.page ?? 1)
        setLimit(filters.limit ? +filters.limit : MAX_PRODUCTS_PER_PAGE)
    }

    // const handleAddToBuild = () => {
    //     setShowConfigurations({ name: "Set Show Configurations"})
    // }

    if(isConfigurator) {
        const selectedModelNumbersAndQty = Object.fromEntries(selectedProducts
            ?.map(product => [`${product[RESULT_PRDT_SKU]}-${product[RESULT_PRDT_INSTANCEID]}-${product[RESULT_PRDT_CATEGORY_ID]}`, product]) ?? [])

        if(!loading && !initialLoading && compatioProducts.length === 0 && selectedProducts?.length === 0) return (
            <div className={`sb3-h-full sb3-max-h-[500px] ${activePage?.[PAGE_LAYOUT] === PAGE_LAYOUTS.GUIDED_SELLING && 'sb3-border-t'} sb3-py-3`}>
                { 
                    activePage?.[PAGE_LAYOUT] === PAGE_LAYOUTS.GUIDED_SELLING && 
                    <Title title={`Select ${uiModel?.[FRMDATA_CONFIG]?.[FRMDATA_DISPLAY_NAME]}`} titleClassName="!sb3-text-lg sb3-pt-3"/>
                }
                <div className="sb3-min-h-full sb3-flex sb3-flex-col sb3-items-center sb3-justify-center">
                    <HiOutlineShoppingBag className="sb3-text-7xl sb3-text-gray-400"/> 
                    <p className='sb3-font-bold sb3-text-gray-400'>No products found</p>
                </div>
            </div>
        );

        // const selectedProductsSkus = selectedProducts?.map((product) => product[RESULT_PRDT_SKU])
        const isGrid = (uiModel?.[FRMDATA_CONFIG]?.[CUSTOMIZATION_VIEW_PREF] ?? categoryDisplayType) === CATEGORY_DISPLAY_TYPES.GRID

        const isCategoryDone = (categories as TProductCategoryField[]).some((cat) => (
            (instance?.[MODEL_DYNAMIC_FIELDS][cat[CATEGORY_ID]][RUNTIME_INSTANCE_MAX_COUNT] ?? 1) 
            > Utils.getUniquePrdtCountByCategoryId(selectedProducts, cat[CATEGORY_ID]) && (instance?.[MODEL_DYNAMIC_FIELDS][cat[CATEGORY_ID]][RUNTIME_INSTANCE_MAX_QUANTITY] ?? 1) 
            > Utils.getProductQtyByCategoryId(selectedProducts, cat[CATEGORY_ID])))
        
        const categoryLabel = uiModel?.[FRMDATA_ITEMS].find((node) => node[FRMDATA_TYPE] === FIELD_TYPES.CATEGORY)?.[FRMDATA_CATEGORY_LABEL]

        return (
            <>
            {
                activePage?.[PAGE_LAYOUT] !== PAGE_LAYOUTS.GUIDED_SELLING && 
                isCategoryDone
                && 
                <CategoryHeader
                    title={categoryLabel}
                    productCount={totalProducts}
                    selectedProducts={selectedProducts}
                    setFilterOpen={setFilterOpen}
                    submodelName={uiModel?.[FRMDATA_CONFIG][FRMDATA_DISPLAY_NAME]}
                    isLoading={loading}
                    setCategorySort ={setCategorySort} 
                    categorySort={categorySort}
                />
            }
            { 
                activePage?.[PAGE_LAYOUT] !== PAGE_LAYOUTS.GUIDED_SELLING && 
                <div className="sb3-flex lg:sb3-inline sb3-items-center sb3-w-1/2 lg:sb3-w-full lg:sb3-space-y-1">
                    {/* <label className="sb3-text-sm lg:sb3-block sb3-hidden sb3-ml-0 sb3-font-bold lg:sb3-m-auto">Sort By:</label> */}
                    <SB2Sort 
                        setCategorySort={setCategorySort} 
                        categorySort={categorySort}
                    />
                </div>
            }

            { 
                activePage?.[PAGE_LAYOUT] !== PAGE_LAYOUTS.GUIDED_SELLING && isCategoryDone && Utils.checkIfClientWebsite() && 
                <div className="sb3-flex sb3-justify-between sb3-space-x-2 sb3-pt-2 sb3-flex-wrap sb3-font-semibold">
                    <div>
                        { 
                            availabilityFlag && 
                            <span className="sb3-flex sb3-text-sm sb3-text-gray-extraDark sb3-items-center sb3-space-x-2">
                                <span>{((initialLoading || isLoading)) ? <SkeletonElement type={"sb3-title !sb3-w-9"}/> : (compatioProducts.length < 50 ? compatioProducts.length : '50+')}</span> 
                                <span>IN STOCK models Available</span>
                            </span>
                        }
                    </div>

                    <div className="sb3-flex sb3-items-center sb3-space-x-2">
                        <span className="sb3-flex sb3-w-max sb3-items-center sb3-space-x-2">
                            <input 
                                type="checkbox" 
                                onChange={() => setAvailabilityFlag(prev => !prev)} checked={availabilityFlag} 
                                className="focus:sb3-outline-none focus:sb3-ring-0 focus:!sb3-shadow-none !sb3-border-0 focus:!sb3-border-0 !sb3-m-0 !sb3-top-0"/> 
                            <label className="sb3-text-sm sb3-font-bold sb3-text-gray-extraDark">{
                            !!builderSpec?.[BUILDER_CONFIG][CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_STOCK_CHECK_TEXT] ?
                            builderSpec?.[BUILDER_CONFIG][CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_STOCK_CHECK_TEXT]
                            : "Show In Stock Only"}</label>
                        </span>
                    </div>
                </div>
            }

            { 
                activePage?.[PAGE_LAYOUT] === PAGE_LAYOUTS.GUIDED_SELLING && 
                <div className="">
                    <h1 className="!sb3-text-lg">{!!categoryLabel ? categoryLabel:
                    `Select ${(uiModel?.[FRMDATA_CONFIG]?.[FRMDATA_DISPLAY_NAME] ?? "")}`}{isRequired && <span className="sb3-text-danger">*</span>}</h1>
                    <div className="sb3-flex sb3-items-center lg:sb3-gap-1">
                        {/* <label className="sb3-text-sm lg:sb3-block sb3-hidden sb3-ml-0 sb3-font-bold lg:sb3-m-auto">Sort By:</label> */}
                        <SB2Sort setCategorySort={setCategorySort} categorySort={categorySort}/>
                    </div>
                </div>
            }

            <div className="sb3-h-full">
                <div>
                    {/* <AnimatePresence> */}
                    {
                        (selectedProducts && selectedProducts.length > 0) &&
                        <div 
                        // initial={{ maxHeight: 0, opacity: 0}}
                        // animate = {{maxHeight: "400px", opacity: 1}}
                        // exit = {{maxHeight: 0, opacity: 0}}
                        className={`sb3-border sb3-border-primary sb3-bg-primary-light sb3-p-2 sb3-mb-2.5 ${platform === THEME_PLATFORMS.BIG_COMMERCE ? "sb3-rounded-lg" : ""}`}
                        style={{
                            backgroundColor:builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_SECONDARY] && 
                            Utils.newShade(builderSpec[BUILDER_CONFIG][CONFIG_CUSTOMIZATION][CUSTOMIZATION_THEME_SETTINGS][THEME_SECONDARY], -10),
                            borderColor: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_SECONDARY] && 
                            Utils.newShade(builderSpec[BUILDER_CONFIG][CONFIG_CUSTOMIZATION][CUSTOMIZATION_THEME_SETTINGS][THEME_SECONDARY], -10)
                        }}                        
                        >
                            {/* @todo change to product name */}
                            {uiModel?.[FRMDATA_CONFIG]?.[FRMDATA_DISPLAY_NAME]} added your outfit: <b>{selectedProducts?.map(product => 
                                // ProductHelper.getProductName(product)
                                `${Utils.isShopify() ? product[RESULT_PRDT_SKU] : product[RESULT_PRDT_MODEL_NO]}${product[RESULT_PRDT_QTY] > 1 ? 'x'+product[RESULT_PRDT_QTY] : ""}`).join(', ')
                            }</b>
                        </div>
                    }
                    {/* </AnimatePresence> */}
                    <div className="sb3-relative -sb3-z-0">
                        {
                            (!initialLoading && !loading && !!instance) ? 
                                isCategoryDone ? 
                                    <>
                                        {isLoading && <div className="sb3-flex sb3-justify-center sb3-w-full"><span className="sb3-animate-spin sb3-text-primary sb3-aspect-square"><FaSpinner/></span></div>}
                                        <InfiniteScroll
                                            dataLength={compatioProducts.length}
                                            next={() => {
                                                return !isLoading && setPage(prev => (prev ?? 1) + 1)}
                                            }
                                            hasMore={((totalProducts) !== 0 
                                                && !isLoading 
                                                && (page * MAX_PRODUCTS_PER_PAGE) < (totalProducts))}
                                            loader={
                                                <>
                                                {
                                                    [...Array(5)].map((_, key) => (
                                                            <ProductSkeleton key = {key}  type = {isGrid ? PRDT_DISPLAY_TYPES.VERTICAL : PRDT_DISPLAY_TYPES.HORIZONTAL}/>
                                                        )
                                                    )
                                                }
                                                </>
                                            }
                                            scrollableTarget="scrollableDiv"
                                            // useWindow={false}
                                            // getScrollParent={() => parentRef.current}
                                            // threshold={200}
                                            className={isGrid ? "sb3-grid sb3-gap-2 sb3-grid-flow-row sb3-grid-cols-[repeat(auto-fill,_minmax(200px,_1fr))]" : ""}
                                        >
                                            
                                            {compatioProducts.map((product, index) => {
                                                const category = categoryIdMap[product[RESULT_PRDT_CATEGORY_ID]]
                                                let buttonLabel = undefined
                                                if(builderSpec && submodel){
                                                    const pageSpec = builderSpec[BUILDER_PAGES].find((spec) => {
                                                        return isArray(spec[PAGE_FORM_DATA]) && spec[PAGE_FORM_DATA].some((data) => data[FRMDATA_SMID] === submodel[MODEL_SUBMODEL_ID])
                                                    })
                                                    const submodelSpec = pageSpec?.[PAGE_FORM_DATA].find((data) => data[FRMDATA_SMID] === submodel[MODEL_SUBMODEL_ID])
                                                    buttonLabel = submodelSpec?.items.find((item) => item.type === "Category")?.[FIELD_BUTTON_LABEL]
                                                }
                                                if(!category) return undefined
                                                
                                                return (
                                                <div key={index} className="compatio-product-sb3-fXCxMgOAT">
                                                    <Product
                                                        category={category}
                                                        product = {product}
                                                        selected = {Object.keys(selectedModelNumbersAndQty).includes(`${product[RESULT_PRDT_SKU]}-${instance[RUNTIME_INSTANCE_INSTANCE_ID]}-${product[RESULT_PRDT_CATEGORY_ID]}`)}
                                                        quantity={selectedModelNumbersAndQty[`${product[RESULT_PRDT_SKU]}-${instance[RUNTIME_INSTANCE_INSTANCE_ID]}-${product[RESULT_PRDT_CATEGORY_ID]}`]?.quantity ?? 0}
                                                        showQtyInput = {(instance[MODEL_DYNAMIC_FIELDS][category[CATEGORY_ID]][RUNTIME_INSTANCE_MAX_QUANTITY] ?? 1) > (Utils.getProductQtyByCategoryId(selectedProducts, category[CATEGORY_ID]))}
                                                        maxQty={instance[MODEL_DYNAMIC_FIELDS][category[CATEGORY_ID]][RUNTIME_INSTANCE_MAX_QUANTITY] ?? 1}
                                                        instance = {instance}
                                                        buttonLabel={(uiModel?.[FRMDATA_ITEMS].find(_categoryB => _categoryB[FRMDATA_ID] === category[CATEGORY_ID])?.[FIELD_BUTTON_LABEL] ?? buttonLabel)}
                                                        type={isGrid ? PRDT_DISPLAY_TYPES.VERTICAL : PRDT_DISPLAY_TYPES.HORIZONTAL}
                                                    />
                                                </div>
                                            )})}
                                        </InfiniteScroll>
                                    </>
                                    :
                                    <div className={isGrid ? "sb3-grid sb3-gap-2 sb3-grid-flow-row sb3-grid-cols-[repeat(auto-fill,_minmax(170px,_1fr))]" : ""}>
                                        {
                                        selectedProducts?.map((product, key) => {
                                            let buttonLabel = undefined
                                            if(builderSpec && submodel){
                                                const pageSpec = builderSpec[BUILDER_PAGES].find((spec) => {
                                                    return isArray(spec[PAGE_FORM_DATA]) && spec[PAGE_FORM_DATA].some((data) => data[FRMDATA_SMID] === submodel[MODEL_SUBMODEL_ID])
                                                })
                                                const submodelSpec = pageSpec?.[PAGE_FORM_DATA].find((data) => data[FRMDATA_SMID] === submodel[MODEL_SUBMODEL_ID])
                                                buttonLabel = submodelSpec?.items.find((item) => item.type === "Category")?.[FIELD_BUTTON_LABEL]
                                            }
                                            const category = categoryIdMap[product[RESULT_PRDT_CATEGORY]]
                                            if(!category) return undefined
        
                                            return (
                                                <Product
                                                    key={key}
                                                    category={category}
                                                    product = {product}
                                                    selected = {true}
                                                    instance = {instance}
                                                    showQtyInput = {(instance[MODEL_DYNAMIC_FIELDS][category[CATEGORY_ID]][RUNTIME_INSTANCE_MAX_QUANTITY] ?? 1) > (Utils.getProductQtyByCategoryId(selectedProducts, category[CATEGORY_ID]))}
                                                    quantity={selectedModelNumbersAndQty[`${product[RESULT_PRDT_SKU]}-${instance[RUNTIME_INSTANCE_INSTANCE_ID]}-${product[RESULT_PRDT_CATEGORY_ID]}`]?.quantity ?? 1}
                                                    maxQty={instance[MODEL_DYNAMIC_FIELDS][category[CATEGORY_ID]][RUNTIME_INSTANCE_MAX_QUANTITY] ?? 1}
                                                    type={isGrid ? PRDT_DISPLAY_TYPES.VERTICAL : PRDT_DISPLAY_TYPES.HORIZONTAL}
                                                    buttonLabel={buttonLabel}
                                                />
                                            )
                                        })
                                        }
                                    </div>
                            :
                            <div className={isGrid ? "sb3-grid sb3-gap-2 sb3-grid-flow-row sb3-grid-cols-[repeat(auto-fill,_minmax(200px,_1fr))]" : ""}>
                                {
                                    [...Array(5)].map((_, key) => (
                                        <ProductSkeleton key = {key} type = {isGrid ? PRDT_DISPLAY_TYPES.VERTICAL : PRDT_DISPLAY_TYPES.HORIZONTAL}/>
                                    )
                                    )

                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
        )
    }

    return (
        <div className="sb3-relative -sb3-z-0">
            <Header title = {uiModel?.[FRMDATA_CONFIG]?.[FRMDATA_DISPLAY_NAME] ?? categories[0][MODEL_DATA][CATEGORY_NAME]}/>
            
            <Table
                columns = {headers}
                data = {compatioProducts}
                dataCallback = {(item) => item}
                loading={loading}
                dataLoading={tableDataLoading}
                pageSize={limit}
                totalRecords={totalProducts}
                fetchData={fetchData}
                updateFilters={updateFilters}
                options= {{
                    footer: true,
                    pagination: "auto",
                    height: "490px",
                    selectable: true,
                    striped: true,
                }}
            />
        </div>
    )
}

export default Categories