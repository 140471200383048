const CartIcon = ({ className = "sb3-text-white" }) => {
  return (
  <svg 
    width="16" 
    height="16" 
    viewBox="0 0 16 16" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    className={className}  // or className if using Tailwind/CSS
  >
    <path d="M5.55493 14.5C5.83107 14.5 6.05493 14.2761 6.05493 14C6.05493 13.7239 5.83107 13.5 5.55493 13.5C5.27879 13.5 5.05493 13.7239 5.05493 14C5.05493 14.2761 5.27879 14.5 5.55493 14.5Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.5547 14.5C11.8308 14.5 12.0547 14.2761 12.0547 14C12.0547 13.7239 11.8308 13.5 11.5547 13.5C11.2785 13.5 11.0547 13.7239 11.0547 14C11.0547 14.2761 11.2785 14.5 11.5547 14.5Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1.05493 1.5H2.35234L4.07789 9.484C4.14118 9.7764 4.30537 10.0378 4.54217 10.2232C4.77897 10.4086 5.07358 10.5063 5.37529 10.4997H11.7196C12.0149 10.4992 12.3012 10.3989 12.5311 10.2154C12.7611 10.0319 12.9211 9.77619 12.9846 9.49043L13.5549 8M3.04645 4.71417H10.0549" stroke="currentColor" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.1641 4.7002H14.9451" stroke="currentColor" stroke-linecap="round"/>
    <path d="M13.5566 3.29688V6.07788" stroke="currentColor" stroke-linecap="round"/>
  </svg>
)};

export default CartIcon