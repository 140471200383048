import { async } from 'q';
import { TAnalyticsData, TAnalyticsOrderData, TAnalyticsOrderDataNotString, TAnalyticsPayload, TAnalyticsProduct, TFilteredData } from 'types/Analytics';
import _, { get as _get } from 'lodash';
import AppSettings from "Settings/AppSettings"
import CompatibleWithHelper from './CompatibleWithHelper';
import Utils from 'Services/Utils';

// const {
//   REACT_APP_AWS_ACCESS_KEY_ID,
//   REACT_APP_AWS_SECRET_ACCESS_KEY,
//   REACT_APP_AWS_REGION,
//   REACT_APP_AWS_TOPICARN,
// } = process.env;
// const customMerchantKey = 'j2m5lq9uNbV4ETc0skb0';

const localDataSet: TFilteredData[] = [];
let retrievedData = JSON.parse(localStorage.getItem('localDataSet') as string) as TFilteredData[];
// console.log(retrievedData, 'retrievedData');

// AWS.config = new AWS.Config();
// AWS.config.accessKeyId = REACT_APP_AWS_ACCESS_KEY_ID;
// AWS.config.secretAccessKey = REACT_APP_AWS_SECRET_ACCESS_KEY;
// AWS.config.region = REACT_APP_AWS_REGION;
// const sns = new AWS.SNS();
// const publisher = async Analyticsdata => {
//   console.log(Analyticsdata, 'one');
//   // var payload = {
//   //   default: JSON.stringify(Analyticsdata),
//   // };
//   // let topicArn = REACT_APP_AWS_TOPICARN;
//   // payload = JSON.stringify(payload);
//   // let resp = [];
//   // let promise = new Promise((resolve, reject) => {
//   //   sns.publish(
//   //     {
//   //       Message: payload,
//   //       MessageStructure: 'json',
//   //       TopicArn: topicArn,
//   //     },
//   //     (err, data) => {
//   //       if (err) console.log(err, err.stack);
//   //       else {
//   //         resp = data;
//   //         resolve(resp);
//   //       }
//   //     }
//   //   );
//   // });
//   // let result = await promise;
//   // // console.log(result, 'result', AWS.config, payload);
//   // return result;
// };

const orderCreate = async (orderCreateData: TAnalyticsOrderDataNotString, filteredData: TFilteredData[]) => {

    // Remove modelVersion field from the root level
    delete orderCreateData.recommendationSource;
    delete orderCreateData.modelVersion;
    delete orderCreateData.applicationID;

    // Modify fields within eventData array
    orderCreateData.eventData.forEach(event => {
        if (event.recommenderInstance === '') {
            event.recommenderInstance = 0;
        }
        delete event.baseFinalProductName;
        delete event.clickAction;
        delete event.finalProductID;
        delete event.parentProductID;
        event.quantity = parseInt(event.quantity as string); // Convert quantity to integer
        event.linePrice = parseFloat(event.linePrice as string);
        event.discountedPrice = parseFloat(event.discountedPrice as string);
    });

    // Convert back to JSON string
    let modifiedJsonString = JSON.stringify(orderCreateData, null, 4);

    const GRAPHQL_BODY: RequestInit = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: modifiedJsonString,
        redirect: 'follow',
    };

    const callApi = await fetch(
        AppSettings.ANALYTICS_URL,
        GRAPHQL_BODY
    )
    .then(response => response)
    .then(result => {
      if (result.ok == true) {
        localStorage.removeItem('localDataSet');
      }
    })
    .catch(error => {
      // console.log('error', error)
    });
  // console.log(callApi, 'callApi', raw);
};

const cartUpdate = async (orderCreateData: TAnalyticsOrderDataNotString, filteredData: TFilteredData[]) => {
  // console.log(orderCreateData, 'cartUpdate', filteredData);
  // var raw = JSON.stringify(orderCreateData);

  // let parsedData = JSON.parse(raw);

  // Remove modelVersion field from the root level
  delete orderCreateData.recommendationSource;
  delete orderCreateData.modelVersion;
  delete orderCreateData.applicationID;

  // Modify fields within eventData array
  orderCreateData.eventData.forEach(event => {
    if (event.recommenderInstance === '') {
      event.recommenderInstance = 0;
    }
    delete event.baseFinalProductName;
    delete event.clickAction;
    delete event.finalProductID;
    delete event.parentProductID;
    event.quantity = parseInt(event.quantity as string); // Convert quantity to integer
    event.linePrice = parseFloat(event.linePrice as string);
    event.discountedPrice = parseFloat(event.discountedPrice as string);
  });

  // Convert back to JSON string
  let modifiedJsonString = JSON.stringify(orderCreateData, null, 4);

  // console.log(modifiedJsonString, 'modifiedJsonString', orderCreateData);

  const GRAPHQL_BODY: RequestInit = {
    method: 'POST',
    headers: {
    'Content-Type': 'application/json',
    },
    body: modifiedJsonString,
    redirect: 'follow',
  };

  const callApi = await fetch(
    AppSettings.ANALYTICS_URL,
    GRAPHQL_BODY
  )
    .then(response => response)
    .then(result => result)
    .catch(error => {
      // console.log('error', error)
    });
  // console.log(callApi, 'callApi', raw);
};

const newPublisher = async (Analyticsdata: TAnalyticsOrderData) => {
  let retrievedData = JSON.parse(localStorage.getItem('localDataSet') as string) as TFilteredData[];
  if (
    Analyticsdata.applicationID == 'Builder' &&
    Analyticsdata.recommendationSource == 'Builder'
  ) {
    Analyticsdata.applicationID = 'SmartBuilder';
    Analyticsdata.recommendationSource = 'SmartBuilder';
  }

  // console.log(Analyticsdata.applicationID ,Analyticsdata.recommendationSource, 'two');
  // console.log(retrievedData, 'retrievedDatas', Analyticsdata);
  if (Analyticsdata.eventType != 'productDetail') {
    if (Analyticsdata.eventType === 'ORDERS_CREATE') {
      Analyticsdata.eventData = JSON.parse(Analyticsdata.eventData as string).products;
      let uniqueEntries = new Set();
      let filteredData = retrievedData.filter(entry => {
        // Convert the entry to a string to be used as a unique identifier
        let entryString = JSON.stringify(entry);
        // If the unique identifier is not in the set, add it and return true to keep the entry
        if (!uniqueEntries.has(entryString)) {
          uniqueEntries.add(entryString);
          return true;
        }
        // If the unique identifier is already in the set, return false to filter out the entry
        return false;
      });

      // Create a map for obj1 for faster lookup
      let obj1Map = new Map();
      for (let item of filteredData) {
        obj1Map.set(item.finalProductID, item);
      }

      // Iterate over obj2 to fill missing or empty values
      for (let item of (Analyticsdata.eventData as TAnalyticsData[] | TAnalyticsProduct[])) {
        if (obj1Map.has(item.purchasedFinalProductID)) {
          let obj1Item = obj1Map.get(item.purchasedFinalProductID);
          // Fill missing or empty values in obj2 with corresponding values from obj1
          for (let key in obj1Item) {
            if (item[key] === undefined || item[key] === '') {
              item[key] = obj1Item[key];
            }
          }
        }
      }

      // console.log(obj2);

      // console.log(obj1,obj2,Analyticsdata)
      // localStorage.removeItem('localDataSet')
      // console.log(Analyticsdata, 'Analyticsdata');
      // console.log(JSON.parse(Analyticsdata.eventData), 'Analyticsdata 2 ');
      await orderCreate((Analyticsdata as TAnalyticsOrderDataNotString), filteredData);
    } else if (Analyticsdata.eventType === 'CARTS_UPDATE') {
      let uniqueEntries = new Set();
      let filteredData = retrievedData.filter(entry => {
        // Convert the entry to a string to be used as a unique identifier
        let entryString = JSON.stringify(entry);
        // If the unique identifier is not in the set, add it and return true to keep the entry
        if (!uniqueEntries.has(entryString)) {
          uniqueEntries.add(entryString);
          return true;
        }
        // If the unique identifier is already in the set, return false to filter out the entry
        return false;
      });

      // Create a map for obj1 for faster lookup
      let obj1Map = new Map();
      for (let item of filteredData) {
        obj1Map.set(item.finalProductID, item);
      }

      Analyticsdata as TAnalyticsOrderDataNotString

      // Iterate over obj2 to fill missing or empty values
      for (let item of (Analyticsdata as TAnalyticsOrderDataNotString).eventData) {
        if (obj1Map.has(item.purchasedFinalProductID)) {
          let obj1Item = obj1Map.get(item.purchasedFinalProductID);
          // Fill missing or empty values in obj2 with corresponding values from obj1
          for (let key in obj1Item) {
            if (item[key] === undefined || item[key] === '') {
              item[key] = obj1Item[key];
            }
          }
        }
      }

      // console.log(obj2);

      // console.log(obj1,obj2,Analyticsdata)
      // localStorage.removeItem('localDataSet')
      await cartUpdate((Analyticsdata as TAnalyticsOrderDataNotString), filteredData);
    } else {
      if (
        Analyticsdata.eventType != undefined &&
        Analyticsdata.recommendationSource !== undefined
      ) {
        var raw = JSON.stringify(Analyticsdata);

        const GRAPHQL_BODY: RequestInit = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: raw,
            redirect: 'follow',
        };

        const callApi = await fetch(
          AppSettings.ANALYTICS_URL,
          GRAPHQL_BODY
        )
          .then(response => response)
          .then(result => result)
          .catch(error => {
            // console.log('error', error)
          });
        // console.log(callApi, 'callApi', raw);
      }
    }
  }
};

const CompatioAnalytics = {
  // TODO: Replace with real API
  analyticsApiUrl:
    'https://1smu9bkxn4.execute-api.us-east-2.amazonaws.com/analytics',

  anonymousIdKey: 'compatio-sb3-anonymous-id',

  // List of allowed event names
  events: {
    xrsCategoryClick: 'CLICK_CAT',
    xrsSortByChange: 'SORT_ORDER_CHANGED',
    arsSortByChange: 'SORT_ORDER_CHANGED',
    arsProductClick: 'PRODUCT_CLICKED',
    xrsProductClick: 'PRODUCT_CLICKED',
    xrsAnchorclick: 'CLICK_BUILD',
    dcAnchorClick: 'BUILDER_LOADED',
    xrsCarouselclick: 'CAROUSEL_CLICKED',
    arsCarouselclick: 'CAROUSEL_CLICKED',
    arsAddToCartClick: 'CART_ADD',
    xrsBundlerClick: 'CART_ADD_BUNDLER',
    xrsAddToCartClick: 'CART_ADD',
    productDetail: 'PRODUCT_DETAILS_PAGE_VIEW',
    addToCart: 'addToCart',
    removeFromCart: 'removeFromCart',
    purchase: 'purchase',
    arsRecShow: 'APPLET_LOADED',
    BuilderClick: 'CART_CONTINUE_TO_BUILD',
  },

  //List of allowed click actions
  clicks: {
    xrsShowMoreClick: 'LOAD_MORE',
    xrsOpenProduct: 'GOTO_VARIANT',
    arsOpenProduct: 'GOTO_VARIANT_ARS',
    xrsOpenCategory: 'OPEN_CAT',
    xrsGotoWidget: 'GOTO_XRS',
    xrsRightScroll: 'RIGHT_CLICK',
    xrsLeftScroll: 'LEFT_CLICK',
    arsRightScroll: 'RIGHT_CLICK_ARS',
    arsLeftScroll: 'LEFT_CLICK_ARS',
    bundlerAddToCart: 'ADD_BUNDLER',
    xrsAddToCart: 'ADD_XRS',
    arsAddToCart: 'ADD_ARS',
    xcsAddToCart: 'ADD_XCS',
    dcGotoWidget: 'GOTO_BUILDER',
    arsRecShown: 'APPLET_LOADED',
    fieldSelect: 'FIELD_SELECT',
  },

  /**
   * Return anonymous user id
   *
   * @returns {string}
   */
  getUserId: function() {
    let userId = localStorage.getItem(this.anonymousIdKey);

    if (!userId) {
      const userId = Utils.getUuid();
      localStorage.setItem(this.anonymousIdKey, userId);
    }

    return localStorage.getItem(this.anonymousIdKey);
  },

  trackNew: async function(event: string, data: TAnalyticsData, appName: string, arsID: string|undefined, appVersion: string, modelShortName?: string, modelId?: string) {
    // console.log(data, appName, arsID, appVersion,'event, data, appName, arsID, appVersion')

    if (retrievedData != null) {
      if (data.products != undefined) {
        data.products.forEach(element => {
          element.recommendationSource = appName;
          element.recommenderInstance = arsID ? arsID : 0;
          element.modelVersion = appVersion ? appVersion : undefined;
          if (modelShortName !== undefined) {
            element.modelShortName = `${modelShortName}`;
          } 
          if (modelId !== undefined) {
            element.modelID = `${modelId}`;
          } 
          retrievedData.push(element);
        });
        // console.log(data.products, 'two')
      } else {
        data.recommendationSource = appName;
        data.recommenderInstance = arsID ? arsID : 0;
        data.modelVersion = appVersion ? appVersion : undefined;
        if (modelShortName !== undefined) {
          data.modelShortName = `${modelShortName}`;
        } 
        if (modelId !== undefined) {
          data.modelID = `${modelId}`;
        } 
        // console.log(data, 'one')
        retrievedData.push(data);
      }
      // Create a Set to keep track of unique combinations
      let uniqueEntries = new Set();
      let filteredData = retrievedData.filter(entry => {
        // Convert the entry to a string to be used as a unique identifier
        let entryString = JSON.stringify(entry);
        // If the unique identifier is not in the set, add it and return true to keep the entry
        if (!uniqueEntries.has(entryString)) {
          uniqueEntries.add(entryString);
          return true;
        }
        // If the unique identifier is already in the set, return false to filter out the entry
        return false;
      });
      let jsonDataString = JSON.stringify(filteredData);
      localStorage.setItem('localDataSet', jsonDataString);
    } else {
      if (data.products != undefined) {
        data.products.forEach(element => {
          element.recommendationSource = appName;
          element.recommenderInstance = arsID ? arsID : 0;
          element.modelVersion = appVersion ? appVersion : undefined;
          if (modelShortName !== undefined) {
            element.modelShortName = `${modelShortName}`;
          } 
          if (modelId !== undefined) {
            element.modelID = `${modelId}`;
          } 
          localDataSet.push(element);
        });
        // console.log(data.products, 'two')
      } else {
        data.recommendationSource = appName;
        data.recommenderInstance = arsID ? arsID : 0;
        data.modelVersion = appVersion ? appVersion : undefined;
        if (modelShortName !== undefined) {
          data.modelShortName = `${modelShortName}`;
        } 
        if (modelId !== undefined) {
          data.modelID = `${modelId}`;
        } 
        // console.log(data, 'one')
        localDataSet.push(data);
      }
      // Create a Set to keep track of unique combinations
      let uniqueEntries = new Set();
      let filteredData = localDataSet.filter(entry => {
        // Convert the entry to a string to be used as a unique identifier
        let entryString = JSON.stringify(entry);
        // If the unique identifier is not in the set, add it and return true to keep the entry
        if (!uniqueEntries.has(entryString)) {
          uniqueEntries.add(entryString);
          return true;
        }
        // If the unique identifier is already in the set, return false to filter out the entry
        return false;
      });
      let jsonDataString = JSON.stringify(filteredData);
      localStorage.setItem('localDataSet', jsonDataString);
    }
  },

  track: async function(event: string, data: TAnalyticsData, appName: string, arsID: string|undefined, appVersion: string, modelShortName?: string, modelId?: string) {
    // console.log(data.products, 'Track Data');
    if (!this.shouldTrackThisEvent(event)) {
      return;
    }

    // Add on Compatio-specific data for certain events
    switch (event) {
      case this.events.productDetail:
      case this.events.addToCart:
      case this.events.xrsProductClick:
        data = this.addProductDetailData(data);
        break;
      default:
      // Do nothing
    }
    if(localStorage.getItem('localDataSet')) {
        let retrievedData = JSON.parse(localStorage.getItem('localDataSet') as string);
    }
    //const authorization = `JWT ${window.compatioConfig.compatioApiKey}`;
    const IsPlatformShopify =
      typeof _get(window, 'compatioConfig.magento') === 'object';
    if (appName !== 'ARS') {
      const payload: TAnalyticsPayload = {
        sessionID: this.getUserId(),
        timestamp: new Date().toISOString(),
        eventType: event,
        merchantKey: AppSettings.ANALYTICS_ENV === "test" ? 'j2m5lq9uNbV4ETc0skb0' : IsPlatformShopify
          ? _get(window, 'compatioConfig.magento.merchantKey')
          : '',
        domainURL: IsPlatformShopify
          ? _get(window, 'compatioConfig.magento.baseUrl')
          : window.location.origin,
        platform: IsPlatformShopify ? 'magento' : 'sb3',
        applicationID: appName,
        modelVersion: appVersion ? appVersion : 'version_info_1',
        recommendationSource: `${appName}`,
        eventData: data.products != undefined ? data.products : [data],
      };
      if (arsID !== undefined) {
        payload.recommenderInstance = `${arsID}`;
      }
      if (modelId !== undefined) {
        payload.modelID = `${modelId}`;
      } 
      if (modelShortName !== undefined) {
        payload.modelShortName = `${modelShortName}`;
      } 
      await newPublisher(payload);
    } else {
      const payload: TAnalyticsPayload = {
        sessionID: this.getUserId(),
        timestamp: new Date().toISOString(),
        eventType: event,
        merchantKey: AppSettings.ANALYTICS_ENV === "test" ? 'j2m5lq9uNbV4ETc0skb0' : IsPlatformShopify
          ? _get(window, 'compatioConfig.magento.merchantKey')
          : '',
        domainURL: IsPlatformShopify
          ? _get(window, 'compatioConfig.magento.baseUrl')
          : window.location.origin,
        platform: IsPlatformShopify ? 'magento' : 'sb3',
        // applicationID: appName ? appName + '-' + arsID : 'xrs',
        applicationID: appName ? appName + '-' + arsID : 'XRS',
        modelVersion: appVersion ? appVersion : 'version_info_1',
        recommendationSource: `${appName}`,
        // recommenderInstance: `${arsID}`,
        eventData: data.products != undefined ? data.products : [data],
      };

      if (arsID !== undefined) {
        payload.recommenderInstance = `${arsID}`;
      }
      if (modelId !== undefined) {
        payload.modelID = `${modelId}`;
      } 
      if (modelShortName !== undefined) {
        payload.modelShortName = `${modelShortName}`;
      } 
      await newPublisher(payload);
    }

    // const analyticCloneData = _.cloneDeep(data,appName,arsID,appVersion)
    // await localStorageData(analyticCloneData)
    // console.log(data,'Track Data');
    //navigator.sendBeacon(this.analyticsApiUrl, JSON.stringify(payload));
    // await publisher(payload);
    // console.log(JSON.stringify(payload), 'payload', AWS.config);
  },

  shouldTrackThisEvent: function(event: string) {
    const onlySendXrsSpecificEvents = AppSettings.ANALYTICS_MODE;

    switch (event) {
      case this.events.productDetail:
      case this.events.addToCart:
        if (onlySendXrsSpecificEvents && !this.isTargetProduct()) {
          return false;
        }
        break;
      default:
        return true;
    }
    return true;
  },

  addProductDetailData(data: TAnalyticsData) {
    const isTargetProduct = this.isTargetProduct();

    data.isTargetProduct = isTargetProduct;

    /**
     * Only add the additional meta data if the current product is a target project, as otherwise the meta
     * data won't exist
     */
    if (isTargetProduct) {
      data.sourceProductVariantID = CompatibleWithHelper.getByKey(
        CompatibleWithHelper.keyMap.sourceMerchantProductVariantID
      );
      data.productPosition = CompatibleWithHelper.getByKey(
        CompatibleWithHelper.keyMap.productPosition
      );
      data.categoryPosition = CompatibleWithHelper.getByKey(
        CompatibleWithHelper.keyMap.categoryPosition
      );
    }

    return data;
  },

  isTargetProduct: function() {
    return CompatibleWithHelper.isCurrentPageTargetProduct();
  },
};

// // Add to global namespace so platform-specific implementations can access this
// window.CompatioAnalytics = CompatioAnalytics;

// // Allow platform-specific implementations to know when window.CompatioAnalytics is ready to use
// if (typeof window.compatioAnalyticsLoaded === 'function') {
//   window.compatioAnalyticsLoaded();
// }

export default CompatioAnalytics;
