import * as types from "types"
import * as consts from 'constants/constants'
import { at, isArray } from "lodash"

/**
 * Nodes are specified to each individual items in a model
 * like submodels, fields, categories and connectors
 * 
 * functions commonly used for nodes
 */

export default class NodeHelper {
  /**
   * filter visible fields/connectors/categories from runtime spec
   * 
   * @param nodes - runtime spec dynamic fields
   * @param filter - optional - Category/Connector/Field
   * @returns {
   *   visibleCategoryIds
   *   visibleFieldIds
   *   visibleConnectorIds
   *  }
   */
  static filterVisibleNodesUsingRuntime(
    nodes: types.TRuntimeDynamicField,
    uiModel: types.TUI_BuilderSubmodel,
    filter?: string
  ) {
    const visibleCategoryIds: string[] = []
    const visibleFieldIds: string[] = []
    const visibleConnectorIds: string[] = []

    Object.entries(nodes)
    .filter(([key, node]) => {
      const fieldExclusionType = uiModel[consts.FRMDATA_ITEMS].find((item) => item[consts.FRMDATA_ID] === key)?.[consts.FRMDATA_FIELD_EXCLUSION]
      // if field exclusion is not defined use default in submodel exclusion type
      const excludeType = !!fieldExclusionType ? fieldExclusionType : uiModel[consts.FRMDATA_CONFIG][consts.FRMDATA_EXCLUSION]
      
      return (
        !node[consts.RUNTIME_INSTANCE_IS_EXCLUDED] 
        || excludeType !== consts.EXCLUSION_OPTIONS.HIDE 
        || !excludeType
      )
    })
    .forEach(([nodeId, node]) => {
      if(
        node[consts.RUNTIME_INSTANCE_FIELD_TYPE] === consts.FIELD_TYPES.CATEGORY && 
       (!filter || filter === consts.FIELD_TYPES.CATEGORY)
      ) return visibleCategoryIds.push(nodeId)

      if(
        node[consts.RUNTIME_INSTANCE_FIELD_TYPE] === consts.FIELD_TYPES.CONNECTOR && 
       (!filter || filter === consts.FIELD_TYPES.CONNECTOR)
      ) 
        return visibleConnectorIds.push(nodeId)
      
      if(!filter || filter === "Field") visibleFieldIds.push(nodeId)
    })

    return {
      visibleCategoryIds,
      visibleFieldIds,
      visibleConnectorIds
    }
  }

  /**
   * get instance specific unique key used for field 
   * as based on instance values has to be unique values
   * 
   * @param field 
   * @param instance 
   * @returns 
   * 
   * @todo update with type for more flexibility
   */
  static getFieldKeyForSelectedValues = (field: types.TField, instance?: types.TRuntimeInstance) => {
    return `${instance?.[consts.RUNTIME_INSTANCE_INSTANCE_ID]}${field[consts.FIELD_ID]}`
  }

  static getFieldKey = (field: string, instance?: types.TRuntimeInstance) => {
    return `${instance?.[consts.RUNTIME_INSTANCE_INSTANCE_ID]}${field}`
  }

  private static checkAnyCategoryIsRequired = (categories: types.TProductCategoryField[]) => {
    return categories.some((cat) => ((cat[consts.MODEL_DATA][consts.FIELD_MIN_QUANTITY] ?? 0) > 0))
  }

  private static checkAnyFieldIsRequired = (fields: types.TField[]) => {
    return fields.some((field) => !!field[consts.FIELD_DATA_CONFIGURATION][consts.CONF_REQUIRED])
  }

  /**
   * Check if submodel is required
   * 
   * submodel is required if any of any of the category/field/connector is required
   * 
   * @param submodel 
   * @returns boolean
   */
  static checkIfSubmodelIsRequired = (submodel: types.TModel) => {
    return this.checkAnyCategoryIsRequired(submodel[consts.MODEL_DATA][consts.MODEL_CATEGORIES]) 
      || this.checkAnyFieldIsRequired(submodel[consts.MODEL_DATA][consts.MODEL_FIELDS])
  }

  /**
   * Check if submodel is required from runtimeSpec
   * 
   * @param {TRuntimeInstance} submodel 
   * @returns boolean
   */
  static checkIfSubmodelIsRequiredByRuntimeSpec = (submodelR: types.TRuntimeInstance, onlyFields?: boolean) => {
    return Object.values(submodelR[consts.MODEL_DYNAMIC_FIELDS])
    .filter(fieldR => (
      !fieldR[consts.RUNTIME_INSTANCE_IS_EXCLUDED]
    ))
    .some((fieldR) => {
      if(fieldR[consts.RUNTIME_INSTANCE_ELEMENT_TYPE] === consts.RUNTIME_ELEMENT_TYPES.FIELD) return fieldR[consts.RUNTIME_INSTANCE_FIELD_REQUIRED]
      else if(fieldR[consts.RUNTIME_INSTANCE_ELEMENT_TYPE] === consts.RUNTIME_ELEMENT_TYPES.CATEGORY) return !onlyFields && (fieldR[consts.RUNTIME_INSTANCE_MIN_QUANTITY] ?? 0) > 0

      return false
    })
  }


  /**
   * Check if submodel is required from runtimeSpec
   * 
   * @param {TRuntimeInstance} submodel 
   * @param {pageType} pageType - conditions differ based on guidedselling or configurator
   * @returns boolean
   */
  static checkIfSubmodelIsDoneByRuntimeSpec = (
    submodelR: types.TRuntimeInstance, 
    pageType?: string, 
    onlyFields?: boolean
  ) => {
    const atleastOneRequired = this.checkIfSubmodelIsRequiredByRuntimeSpec(submodelR, onlyFields)

    // if not field is required
    if(!atleastOneRequired) {
      if(onlyFields) return true
      // if guided selling
      // all fields are selected
      if(pageType === consts.PAGE_LAYOUTS.GUIDED_SELLING) 
        return Object.values(submodelR[consts.MODEL_DYNAMIC_FIELDS])
        .filter((fieldR) => {
          if(onlyFields && fieldR[consts.RUNTIME_INSTANCE_ELEMENT_TYPE] === consts.RUNTIME_ELEMENT_TYPES.CATEGORY) 
            return false
          return !fieldR[consts.RUNTIME_INSTANCE_IS_EXCLUDED]
        })
        .some((fieldR) => {
          if(fieldR[consts.RUNTIME_INSTANCE_ELEMENT_TYPE] === consts.RUNTIME_ELEMENT_TYPES.FIELD 
            && this.checkIfAllChoicesAreDisabled(fieldR[consts.RUNTIME_INSTANCE_CHOICES])) return true

          return (isArray(fieldR[consts.RUNTIME_INSTANCE_FIELD_VALUE]) 
            && fieldR[consts.RUNTIME_INSTANCE_FIELD_VALUE].length > 0)
        })

      // if not guided selling
      // at least one category is selected
      return Object.values(submodelR[consts.MODEL_DYNAMIC_FIELDS])
      .filter((fieldR) => !fieldR[consts.RUNTIME_INSTANCE_IS_EXCLUDED] 
        && fieldR[consts.RUNTIME_INSTANCE_ELEMENT_TYPE] === consts.RUNTIME_ELEMENT_TYPES.CATEGORY
      )
      .some((fieldR) => {
        return isArray(fieldR[consts.RUNTIME_INSTANCE_FIELD_VALUE]) 
        && fieldR[consts.RUNTIME_INSTANCE_FIELD_VALUE].length > 0 
        && fieldR[consts.RUNTIME_INSTANCE_FIELD_VALUE].length >= (fieldR[consts.RUNTIME_INSTANCE_MIN_QUANTITY] ?? 0)
      })
    }

    // if there are required fields
    return Object.values(submodelR[consts.MODEL_DYNAMIC_FIELDS])
    .filter((fieldR) => {
      if(fieldR[consts.RUNTIME_INSTANCE_ELEMENT_TYPE] === consts.RUNTIME_ELEMENT_TYPES.CATEGORY) {
        if(onlyFields) return false
        return (fieldR[consts.RUNTIME_INSTANCE_MIN_QUANTITY] ?? 0) > 0 && !fieldR[consts.RUNTIME_INSTANCE_IS_EXCLUDED]
      }
      return fieldR[consts.RUNTIME_INSTANCE_FIELD_REQUIRED] && !fieldR[consts.RUNTIME_INSTANCE_IS_EXCLUDED]
    })
    .every((fieldR) => {
      // if field
      if(fieldR[consts.RUNTIME_INSTANCE_ELEMENT_TYPE] === consts.RUNTIME_ELEMENT_TYPES.FIELD) {
        const allDisabled = this.checkIfAllChoicesAreDisabled(fieldR[consts.RUNTIME_INSTANCE_CHOICES])
        const selectedValue = this.getFieldValueByFieldR(fieldR)

        if(fieldR[consts.RUNTIME_INSTANCE_FIELD_TYPE] === consts.FIELD_TYPES.MULTI_CHOICE){
          return allDisabled || (isArray(fieldR[consts.RUNTIME_INSTANCE_FIELD_VALUE]) 
          && fieldR[consts.RUNTIME_INSTANCE_FIELD_VALUE].length > 0 
          && ((fieldR[consts.RUNTIME_INSTANCE_MIN_QUANTITY] ?? 0) <= fieldR[consts.RUNTIME_INSTANCE_FIELD_VALUE].length) )
        }
        
        return !!selectedValue
      }

      // if category
      if(fieldR[consts.RUNTIME_INSTANCE_ELEMENT_TYPE] === consts.RUNTIME_ELEMENT_TYPES.CATEGORY) {
        return isArray(fieldR[consts.RUNTIME_INSTANCE_FIELD_VALUE]) 
        && fieldR[consts.RUNTIME_INSTANCE_FIELD_VALUE].length > 0 
        && ((fieldR[consts.RUNTIME_INSTANCE_MIN_QUANTITY] ?? 0) <= fieldR[consts.RUNTIME_INSTANCE_FIELD_VALUE].length) 
      }

      return true
    })
  }

  /**
   * 
   * @param instance 
   * @param field 
   * @returns 
   * 
   * @deprecated 
   * @use getFieldValueByFieldR
   */
  static getFieldValue = (instance: types.TRuntimeInstance, field: types.TField) => {
    // from runtime spec
    let instanceValue = (instance as types.TRuntimeInstance)?.[consts.MODEL_DYNAMIC_FIELDS]?.[field[consts.FIELD_ID]][consts.RUNTIME_INSTANCE_FIELD_VALUE]

    if(instanceValue){
      if(!isArray(instanceValue)) instanceValue = [instanceValue]

      if(field[consts.FIELD_TYPE] === consts.FIELD_TYPES.MULTI_CHOICE)
        return instance[consts.MODEL_DYNAMIC_FIELDS][field[consts.FIELD_ID]][consts.RUNTIME_INSTANCE_CHOICES]?.filter((choice) => (instanceValue as (string|number)[]).includes(choice[consts.CHOICE_NAME]))
      else  
        return instanceValue
    }
    return undefined
  }

  static getFieldValueByFieldR = (instanceField: types.TRuntimeDynamicFieldValue) => {
    // from runtime spec
    let instanceValue = (instanceField as types.TRuntimeDynamicFieldValue)[consts.RUNTIME_INSTANCE_FIELD_VALUE]

    if(instanceValue){
      if(!isArray(instanceValue)) instanceValue = [instanceValue]

      if(instanceField[consts.RUNTIME_INSTANCE_FIELD_TYPE] === consts.FIELD_TYPES.MULTI_CHOICE)
        return instanceField[consts.RUNTIME_INSTANCE_CHOICES]?.filter((choice) => (instanceValue as (string|number)[]).includes(choice[consts.CHOICE_NAME]))
      else  
        return instanceValue
    }
    return undefined
  }

  static getOptionsFromChoices = <T extends Object>(choices: types.TChoice[], callback: (choice: types.TChoice) => T) => {
    return choices?.reduce((acc: T[], choice) => {
        acc.push(callback(choice))
        return acc
    }
    , [])
  }


  static checkIfAllChoicesAreDisabled = (choices?: types.TChoice[]) => {
    return choices?.length === 0 || choices?.every((choice: types.TChoice) => choice[consts.CHOICE_IS_EXCLUDED])
  }

  // get choice ids from choices
  static getChoicesIds = (choices: types.TChoice[]) => {
    return choices.map((choice: types.TChoice) => choice[consts.CHOICE_ID])
  }
}